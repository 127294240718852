.nested-column {
    font-size: 12pt;
}


@font-face {font-family:"MICR Encoding";src:url("micr-e13b.ttf") format("truetype");font-weight:normal;font-style:normal;}


.micr {
   margin-top: -16px;
    text-align: center;
    font-family:"MICR Encoding";
    font-size: 19pt;
    white-space: nowrap;
}
input {
    border: none;
    outline: none;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
}


.check-company{
    font-size: 14pt;
    font-weight: 700;
}


.check-bank{
    font-size: 13pt;
    font-weight: 700;
}

.check-number{
    font-size: 14pt;
    font-weight: 700;
}

.check-dollar{
    font-size: 14pt;
    font-weight: 700;
}

.check-amount-words{
    font-size: 14pt;
    font-weight: 700;
}

#name-payto {
    font-weight: 700;
    padding-top: 40px;
    font-size: 14pt;
    border: none;
}


.check-container {
    /*position: relative;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
    /*border: 1px solid black;*/
    width: 97%;
    height: 407px;
}
.check-container__header-banner,
.check-container__payto,
.check-container__dollar-word,
.check-container__footer {
    font-size: 14pt;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}
.check-container__name-address {
    display: flex;
    flex-direction: column;
}
#date-input::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.check-container__order {
    display: flex;
    flex: 1;
}
.check-container__date-border {
    border-bottom: 1px solid black;
    padding: 4px 24px;
}
.check-container__dollar-border {
    border: 1px solid black;
    padding: 6px 38px;
}

.check-pay-to-the-order-of{
        padding-top: 11px;
}


.check-container__payto-desc {
    width: 100px;
    display: flex;
    flex-wrap: nowrap;
}
.check-container__dollar-word,
.check-container__memo,
.check-container__signature,
.check-container__order {
    border-bottom: 1px solid black;
}

.check-container__memo {
    display: flex;
    flex: 1.5;
}
.check-container__signature-word,
.check-container__memo-word {
    margin-left: 8px;
    border: none;
    margin-bottom: 0px;
}

.check-container__signature {
    display: flex;
    flex: 1;
    margin-left: 50px;
}

.check-container__signature img{
    height: 100px;
    margin-top: -70px !important;
}