.filterTextFieldWithPredefinedSelect {
    border: 1px solid #BBBBBB;
    border-radius: 5px;
    margin: 10px;
}

.filterTextFieldWithPredefinedSelectName {
    min-width: 250px;
    width: 250px;
    text-align: right;
}

.filterTextFieldWithPredefinedSelectOptions {
    min-width: 234px;
    width: 234px;
    height: 40px;
    margin: 10px;
    text-align: left;
}

.filterTextFieldWithPredefinedSecondarySelectOptions {
    min-width: 234px;
    width: 234px;
    height: 40px;
    text-align: left;
}

.filterTextFieldWithPredefinedEmptyDiv {
    width: 100%;
    text-align: right;
}

.filterTextFieldWithPredefinedInput {
    min-width: 330px;
    width: 330px;
    text-align: left;
}

.filterTextFieldWithPredefinedInput .MuiOutlinedInput-input {
    padding: 1px 7px !important;
    margin: 3.9px !important;
}