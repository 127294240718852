.actionDropdownSelect {
    border: 1px solid #BBBBBB;
    border-radius: 5px;
    margin: 10px;
}

.actionDropdownSelectName {
    min-width: 250px;
    width: 250px;
    text-align: right;
}

.actionDropdownSelectOptions {
    min-width: 234px;
    width: 234px;
    height: 40px;
    margin: 10px;
    text-align: left;
}

.custom-form-control .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(23px, 5px) scale(0.75);
    align-content: center;
}

.actionDropdownSelectOptionsForSetShippingAccount {
    min-width: 478px;
    width: 478px !important;
    height: 40px;
    margin: 10px;
    text-align: left;
}

.actionDropdownEmptyDiv {
    width: 100%;
    text-align: right;
}