.table-cell-icon {
    width: 225px !important;
    max-width: 225px !important;
}

.settings-table-cell {
    width: 200px !important;
    height: 70px !important;
    font-weight: normal !important;
    border-left: none;
    border-right: none;
    text-align: left !important;
    padding: 0.2em !important;
}

.table-cell-center {
    text-align: center !important;
    padding: 3px !important;
}

.table-cell-left {
    text-align: left !important;
    padding: 3px !important;
}

.table-cell-right {
    text-align: right !important;
    padding: 3px !important;
}

.table-cell-center-thin {
    text-align: center !important;
    padding: 3px !important;
    width: 130px !important;
}

.add-related-product-popper-container {
    width: 290px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.add-related-product-popper-item {
    margin-bottom: 15px;
}

.loading-animation {
    animation: spin 1s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.add-related-product-popper-buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

