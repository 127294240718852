.drawer-header {
    display: flex;
    align-items: center;
    height: 75px;
    justify-content: space-between;
    background-color: #005E73;
    color: white;
    padding-bottom: 12px;
}

.drawer-header-text {
    margin-top: 15px;
    margin-left: 15px;
    font-size: 20px;
}

.drawer-header-close-icon {
    float: right;
    margin-top: 15px;
    margin-right: 9px;
}

.MuiDrawer-paper {
    top: 0;
    flex: 1 0 auto;
    height: 100%;
    display: flex;
    outline: 0;
    z-index: 1200;
    position: fixed;
    overflow-y: auto;
    flex-direction: column;
    width: 30%;
    -webkit-overflow-scrolling: touch;
}

.drawer-close-icon {
    fill: white !important;
    font-size: 31px !important;
}

.alerts-drawer {
    overflow: auto;
}

@media screen and (max-width: 800px) {
    .MuiDrawer-paper {
        width: 50%;
    }
}

@media screen and (max-width: 600px) {
    .MuiDrawer-paper {
        width: 70%;
    }
}
