.shortcode-head-cell {
    text-align: center !important;
    min-width: 100px;
    white-space: nowrap;
    font-weight: bold !important;
    top: 0px;
    z-index: 17;
    position: sticky;
    background-color: white;
    box-shadow: 0px 0px 0px 1px #e0e0e0;
    border-left: 1px solid rgba(224, 224, 224, 1) !important;
    border-right: 1px solid rgba(224, 224, 224, 1) !important;
    border-top: 1px solid rgba(224, 224, 224, 1) !important;
}


.text-cell {
    min-width: 125px;
    text-align: center !important;
    font-weight: bold !important;
    border-right: 1px solid rgba(224, 224, 224, 1) !important;
}

.shortcode-label-cell {
    min-width: 250px;
    text-align: right !important;
    font-weight: bold !important;
    position: sticky;
    left: 0px;
    top: 0px;
    background-color: white;
    z-index: 18;
    box-shadow: 0px 0px 0px 1px #e0e0e0;
    border-top: 1px solid rgba(224, 224, 224, 1) !important;
}

.heading-cell {
    min-width: 250px;
    text-align: right !important;
    font-weight: bold !important;
    position: sticky;
    left: 0px;
    top: 0px;
    background-color: white;
    z-index: 17;
    box-shadow: 0px 0px 0px 1px #e0e0e0;
    border-right: 1px solid rgba(224, 224, 224, 1) !important;
    border-top: 1px solid rgba(224, 224, 224, 1) !important;
}

.last-heading-cell {
    min-width: 250px;
    text-align: right !important;
    font-weight: bold !important;
    position: sticky;
    left: 0px;
    top: 0px;
    background-color: white;
    z-index: 16;
    box-shadow: 0px -2px 0px 1px #e0e0e0;
    border-right: 1px solid rgba(224, 224, 224, 1) !important;
    border-top: 1px solid rgba(224, 224, 224, 1) !important;
}

.detail-heading-cell {
    min-width: 250px;
    text-align: right !important;
    font-weight: bold !important;
    padding: 0px !important;
    position: sticky;
    left: 0px;
    background-color: white;
    z-index: 17;
    box-shadow: 1px 1px 0px 0px #e0e0e0;
    border-right: 1px solid rgba(224, 224, 224, 1) !important;
    border-top: 1px solid rgba(224, 224, 224, 1) !important;
}

.bottom-row-cell {
    border-bottom: solid !important;
}

.details-table {
    width: 100%;
}

.empty-head-col {
    column-span: all;
    border-bottom: none !important;
}

.empty-cell {
    width: 100%;
    border-bottom: none !important;
    border-right: none !important;
}

.sub-head-date-col {
    min-width: 125px;
    text-decoration: underline !important;
    font-weight: bold !important;
    text-align: center !important;
    border-bottom: none !important;
    border-right: 1px solid rgba(224, 224, 224, 1);
}

.sub-head-type-col {
    min-width: 125px;
    text-decoration: underline !important;
    font-weight: bold !important;
    text-align: center !important;
    border-bottom: none !important;
    border-left: 1px solid rgba(224, 224, 224, 1);
}

.shortcode-empty-col {
    min-width: 100px;
    border-bottom: none !important;
    border-right: 1px solid rgba(224, 224, 224, 1);
}

.details-table-cell-date-borders {
    min-width: 125px;
    text-align: center !important;
    border-bottom: none !important;
    border-right: 1px solid rgba(224, 224, 224, 1);
}

.details-table-cell-type-borders {
    min-width: 125px;
    text-align: center !important;
    border-bottom: none !important;
    border-left: 1px solid rgba(224, 224, 224, 1);
}

.details-table-cell-type-borders-gray {
    min-width: 125px;
    text-align: center !important;
    border-bottom: none !important;
    border-left: 1px solid rgba(224, 224, 224, 1);
    color: rgb(190, 190, 190) !important;
}

.details-table-cell-borders {
    border: 1px solid rgba(224, 224, 224, 1);
    text-align: center !important;
}


.empty-head-details-col {
    column-span: all !important;
    border-bottom: none !important;
}

.empty-details-cell {
    width: 100%;
    column-span: all;
    border-bottom: none !important;
}

.details-table-cell-borders-gray {
    border-left: 1px solid rgba(224, 224, 224, 1);
    border-right: 1px solid rgba(224, 224, 224, 1);
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    border-top: 1px solid rgba(224, 224, 224, 1);
    text-align: center !important;
    color: rgb(190, 190, 190) !important;
}

.sticky-table{
    margin-top: 15px;
}

.sticky-table-container-dialog{
    overflow: hidden;
}

.dialog-cell{
    min-width: 175px;
    text-align: center !important;
    font-weight: bold !important;
    border: 1px solid rgba(224, 224, 224, 1);
}