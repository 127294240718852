.actionDropdownInput {
    border: 1px solid #BBBBBB;
    border-radius: 5px;
    margin: 10px;
}

.predefinedDropdownInput {
    min-width: 234px;
    width: 234px;
    height: 40px;
    margin: 10px;
    text-align: left;
}

.actionDropdownInputName {
    min-width: 250px;
    width: 250px;
    text-align: right;
}

.actionDropdownInputOptions {
    min-width: 70px;
    width: 70px;
    height: 40px;
    text-align: left;
}

.actionDropdownInputOptions2 {
    min-width: 150px;
    width: 150px;
    height: 40px;
    margin: 10px;
    text-align: left;
}

.actionDropdownInputOptions3 {
    min-width: 315px;
    width: 315px;
    height: 40px;
    margin: 10px;
    text-align: left;
}

.actionDropdownInputOptions3_2 {
    min-width: 234px;
    width: 234px;
    height: 40px;
    text-align: left;
}

.actionDropdownInputEmptyDiv {
    width: 100%;
    text-align: right;
}

.actionDropdownInputEmptyDiv_WithCheckBox {
    width: 80%;
    text-align: right;
}

.actionDropdownInputFilterTextFieldInput{
    min-width: 180px;
    width: 180px;
    margin: 10px !important;
    text-align: left;
}

.actionDropdownInputFilterTextFieldInput .MuiOutlinedInput-input {
    padding: 10.5px 7px !important;
}