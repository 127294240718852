.updateShipmentBtnBox {
    display: flex;
    justify-content: center;
    text-align: center;
}

.updateShipmentBtn {
    margin: 10px;
}

.updateShipmentHeaderBox {
    justify-content: center;
    text-align: center;
}

.checkCircleBox {
    min-width: 40px;
}