
.rowSpec {
    display: flex;
    justify-content: center;
    width: 100%;
}

.row-register-new-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.register-new-user-TwoInput {
    margin: 10px !important;
    width: 50%;
    max-width: 400px;
}

.register-new-user-Input {
    margin: 10px !important;
    max-width: 820px;
    width: 97%;
}

.register-new-user-role-box {
    margin: 10px !important;
    max-width: 820px;
    width: 97%;
}

.register-new-user_btn {
    margin: 10px 10px 10px 10px !important;
}

.rowCol {
    display: flex;
    flex-direction: column;
}

.space {
    margin: 10px 0 !important;
}

.first-input {
    margin-right: 19% !important;
}

.register-new-user-title {
    margin: 20px 10px 0px 10px !important;
}

.register-new-user-description {
    margin: 20px 10px 10px 10px !important;
}

.location-select-section {
    margin: 20px 10px 10px 10px !important;
}

@media screen and (max-width: 600px) {
    .rowSpec {
        display: grid !important;
        width: 100%;
        grid-template-columns: 100%;
        min-width: 40%;
    }

    .register-new-user-description,  .register-new-user-Input {
        margin: 5px !important;
        width: 94% !important;
    }

    .register-new-user-role-box, .register-new-user-TwoInput {
        margin: 5px !important;
        width: 100% !important;
    }

    .location-select-section {
        display: grid !important;
        width: 100%;
        grid-template-columns: 50% 30% 20%;
        min-width: 40%;
    }
}
