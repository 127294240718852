.margin20px {
    padding: 20px;
}

.horizMargin20px {
    width: 20px;
}

.spacer {
    width: auto;
}

.container-row {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.datePicker {
    width: 300px;
}

.button {
    height: 36px;
    padding-top: 8px;
}