.emailTemplateWidthChange {
    width: 5vw !important;
}

.email-textBox {
    margin-left: -5vw !important;
}

.email-inputBox {
    width: 200px;
    min-width: 200px;
    min-height: 45px;
}

.empty-line {
    min-height: 55px;
}

.next-spacer {
    width: 20px;
}

.typeBox {
    margin: 10px !important;
    width: 715px;
}

.nameBox {
    margin: 10px !important;
    width: 715px;
}

.subjectContainer {
    margin: 10px !important;
    width: 715px;
}

.subjectBox {
    width: 450px;
}

.ORdiv {
    margin-left: 10px;
    margin-right: 10px;
}

.subjectDropdown {
    width: 245px;
}

.bodyBox {
    margin: 10px !important;
    width: 715px;
}

.deleteBox {
    width: 12vw;
}






