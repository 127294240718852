.missing-labels-container-left {
    background-color: #fff;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.23);
    height: 400px;
    margin-right: 1%;
    border-radius: 4px;
    border-width: 1px;
    width: 49%;
}

.missing-labels-container-right {
    background-color: #fff;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.23);
    height: 400px;
    margin-left: 1%;
    border-radius: 4px;
    border-width: 1px;
    width: 49%;
}

.missing-labels-table {
    display: flex;
    flex-direction: column;
    height: 300px;
    overflow: auto;
}

.report-title-row {
    background-color: #fff;
    padding-right: 1.6%;
    padding-left: 1.3%;
    padding-top: 1.3%;
    display: flex;
    justify-content: start;
    align-items: center;
}

.reports-table-container {
    background-color: #fff;
    margin: 20px;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.23);
    /*width: 100%;*/
    height: 400px;
    /*overflow: auto;*/
}
.missing-labels-table-content {
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
}

.report-icon {
    padding: 10px;
}

.date-row {
    margin-top: 20px;
    margin-left: 20px;
}

.table-cell {
    min-width: 100px;
}

@media screen and (max-width: 1200px) {
    .missing-labels-container-left {
        width: auto;
        margin-right: 0;
        margin-bottom: 20px;
    }
    .missing-labels-container-right {
        width: auto;
        margin-left: 0;
        margin-bottom: 20px;
    }
    .reports-table-container {
        width: auto;
    }
}