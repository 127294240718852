.updateShipmentBtnBox {
    display: flex;
    justify-content: center;
    text-align: center;
}

.updateShipmentBtn {
    margin: 10px;
}

.updateShipmentHeaderBox {
    justify-content: center;
    text-align: center;
}

.category-checkCircleBox {
    min-width: 80px;
}

.category-endingBox {
    width: 100%;
}

.category-input-spec {
    width: 225px;
}

.category-textBox {
    padding-top: 10px;
    margin-left: 14px;
}
.category-inputBox {
    width: 300px;
    min-width: 200px;
    min-height: 45px;
}

.createcategoryButtonBox {
    display: flex;
    justify-content: center;
    text-align: center;
}

.createcategoryButton {
    margin: 10px;
}
