.toolbar {
    background: #acd685;
    position: sticky !important;
    top: 75px;
    z-index: 20;
}
.contextualMenu {
    width: 100%;
    min-width: 60%;
    display: flex;
}
.contextualMenuText {
    float: left;
    margin-top: 11px;
    margin-right: 12px;
    width: 90px;
}

.dialog {
    position: absolute !important;
    left: 150px;
    top: 90px;
}

.toolbar .titleTypography {
    position: sticky !important;
    top: 75px;
    z-index: 20;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
    text-align: right;
}

.refresh {
    float: left;
}

.toolbarCancelBtn {
    padding-top: 5px;
}

@media screen and (max-width: 800px) {
    .contextualMenu {
        width: fit-content;
    }
}