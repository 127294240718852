.table-container {
    width: 100%;
    overflow-x: auto;
}

.table-wrapper {
    width: 100%;
    table-layout: fixed;
}

.header-title {
    margin: 15px;
}

.icon-cell {
    width: 30px !important;
}

.table-cell {
    width: 200px !important;
    height: 70px !important;
    font-weight: normal !important;
    border-left: none;
    border-right: none;
    text-align: left !important;
    padding: 0.2em !important;
}