.notesSidebarHolder {
    position: absolute;
    top: 140px;
    right: 20px;
    box-shadow: -3px 3px 20px 0px rgba(0,0,0,0.2);
    background: white;
    height: calc(100% - 140px);
    transition: width 0.3s ease-in-out;
    overflow: auto;
    z-index: 1000;
}

.notesTransactionContainer{
    margin-bottom: 30px;
    margin-top: 15px;
    width: 100%;
}
