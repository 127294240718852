.editIcon {
    width: 82px !important;
}

.damage-cell-center {
    text-align: center !important;
    padding: 3px !important;
}

.damage-cell-center-thin {
    text-align: center !important;
    padding: 3px !important;
    width: 130px !important;
}
