.attachments-chip-container {
    display: flex;
    position: relative;
}

.attachment-chip {
    margin-right: 5px;
}

.custom-file-chip {
    fill: #61dafb;
    text-overflow: ellipsis;
    max-width: 95%;
}