.title-row {
    display: flex;
    justify-content: space-around;
    margin-top: 25px;
    margin-bottom: 15px;
    margin-right: 10px;
}

.title-row2 {
    display: flex;
    margin-top: 25px;
    margin-bottom: 15px;
    margin-right: 10px;
}

.textfields {
    width: 100%;
    margin-left: 10px;
}

.textfields2 {
    width: 24.5%;
    margin-left: 10px;
}

.notesField {
    width: 99%;
    margin-top: 15px;
    margin-left: 10px;
}

.centre-cell {
    text-align: center;
}

.bottom-wrapper {
    width: 100%;
    margin-top: 1%;
}

.submit-button {
    float:right;
    margin-right: 1% !important;
}

.cancel-button {
    margin-right: 10px;
    margin-bottom: 10px;
    float: right;
}

.no-border {
    border-bottom: none;
}

.top-border {
    border-top-style: solid;
}

.left-border {
    border-left-style: solid;
}

.right-border {
    border-right-style: solid;
}

.no-border {
    border-bottom: none !important;
}

.center {
    text-align: center !important;
}

.expanding-row {
    padding-bottom: 0 !important;
}

.shrink-qty-date-field {
    width: 100px;
    text-align: center !important;
}

.expanded-row {
    padding: 0 !important;
}

/*.MuiIconButton-root {
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 0 !important;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}*/

.received-to-date-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: 50px 100px 125px 50px;
    grid-template-rows: auto auto auto;
    align-items: center;
}

.qty-received {
    text-align: right;
}

.date-received {
    text-align: right;
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.delete-icon {
    margin-bottom: 1px;
    margin-left: 50px;
}

.cellPadding {
    padding: 5px !important;
}

.content-table {
    width: 90%;
    padding-left: 16px;
}

.qty-received-cell {
    display: flex;
    justify-content: left;
    align-items: center;
}

.table-right-border {
    border-right: 1px rgba(0, 0, 0, 0.1) solid;
}

.qty-received-header {
    width: 70%;
    border-right: 1px rgba(0, 0, 0, 0.1) solid;
}

.delete-buttons {
    display: flex;
    flex-direction: row;
}

.delete-button {
    white-space:normal !important;
    word-wrap:break-word;
}

.content-table-titles {
    font-weight: bold;
}

.table {
    margin-top: 25px;
}

.empty-div {
    /*width: 200px;*/
}

.pdf-row {
    width: 100%;
    margin-left: 10px;
}

.pdf-icon {
    width: 70px;
    margin-left: 5px;
    margin-right: 30px;
}

.shortcode-field {
    width: 215px;
}

@media (max-width: 800px) {
    .shortcode-field {
        width: 150px;
    }
}

@media (max-width: 600px) {
    .textfields {
        margin: 10px;
        width: 100%;
        margin: 10px;
    }
    .title-row{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        position: relative;
    }
    .pdf-row {
        width: 79%;
        margin-top: 10px;
        float: left;
    }
    .pdf-icon {
        width: 14%;
        margin-left: 10px;
        margin-top: 10px;
    }
}