.ftl-bol-page {
    text-align: center;
    margin: auto;
    width: 40%;
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ftl-bol-fields {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
}

.add-delete-col {
    width: 5% !important;
}

.ftl-item-shortcode-field {
    width: 95%;
    text-align: center;
    align-self: center;
    display: inline-block;
}

.add-ftl-item {
    column-span: 5;
    width:4%;
    border-bottom: 0px !important;
}

.ftl-stepper-buttons {
    margin-top: 10px;
    text-align: center;
}

.ftl-address-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 25px;
    margin-right: 25px;
}

.ftl-form-header {
    margin: 10px !important;
    padding-right: 1.5%;
    text-align: left;
}

.ftl-row-spec {
    display: flex !important;
    width: auto;
    min-width: 40%;
}

.FTLFormSelect{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
}


.FTLFormTextField{
    margin-left: 10px !important;
    margin-right: 10px !important;
    width: 100px !important;
}