.rowSpec {
    display: flex !important;
    width: auto;
    min-width: 40%;
}

.rowCol {
    display: flex;
    flex-direction: column;
}

.rowSelects {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: start;
}

.rowMarketplace {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.innerBox {
    margin-top: 10px;
}

.logoinnerBox {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.formSelect {
    margin-left: 10px !important;
    margin-right: 5px !important;
}

.formInput {
    margin: 10px 10px 10px 0 !important;
    width: 223px;
}

.formInputsingle {
    margin: 10px !important;
    width: 715px;
}

.formTwoColInput {
    margin: 10px !important;
    width: 470px;
}

.formThreeInput {
    margin: 10px !important;
    width: 225px
}

.formTwoInput {
    margin: 10px !important;
    width: 225px
}

.marketplace_stepper_align {
    text-align: center;
}

.marketplace_stepper_btn {
    margin: 10px 10px 10px 10px !important;
}

.logoUploadBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}