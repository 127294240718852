.topToolbar {
    background: #acd685;
    position: sticky !important;
    top: 75px;
    z-index: 20;
}

.topToolbar .titleTypography {
    position: sticky !important;
    top: 75px;
    z-index: 20;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
    text-align: right;
}

.menu-item-title {
    white-space: nowrap;
}