.filterNumericField {
    border: 1px solid #BBBBBB;
    border-radius: 5px;
    margin: 10px;
}

.filterNumericFieldName {
    min-width: 250px;
    width: 250px;
    text-align: right;
}

.filterNumericFieldOptions {
    min-width: 234px;
    width: 234px;
    height: 40px;
    margin: 10px;
    text-align: left;
}

.filterNumericFieldInput {
    min-width: 234px;
    width: 234px;
    margin: 10px;
    text-align: left;
}

.filterNumericFieldInput .MuiOutlinedInput-input {
    padding-top: 10.5px !important;
    padding-right: 7px !important;
    padding-bottom: 10.5px !important;
    padding-left: 7px !important;
}

.order-item-warning {
    margin-left: 95px;
}