.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox {
    padding: 0px 12px 0 3px !important;
}
/*.MuiTableCell-root {
    padding-left: 5px !important;
}*/
.table th {
    font-weight: 700;
    padding: .20rem !important;
    vertical-align: middle !important;
}
.table td {
    vertical-align: middle !important;
}
.conditionFiltersContainer {
    /* do not change. 
     * this works in conjunction with min-height defined in ConditionDialog.conditionFiltersDialogContent.
     * this gives scroll to the content below the search bar */
    max-height: 475px; 
}

