.subTitle {font-size: 30px;
    margin: 20px;
    margin-left: 34px;}

.listingsSettingIntro {
    font-size: 20px;
    margin-left: 34px;
}

.displayTriggerCheck {
    font-size: 18px;
    margin-left: 34px;
    margin-bottom: 30px;
    margin-top: 20px;
}

.autoApplyCheckbox{
    margin-top: 40px;
    margin-left: 34px;
}

.bottom-wrapper {
    width: 100%;
    margin-top: 1%;
    display: flex;
    justify-content: center; /* This centers the children horizontally */
    align-items: center; /* This centers the children vertically (if needed) */
    padding: 10px;
}

.automationRulesFilterButton {
    width: 125px;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    position: absolute;
    right: 0;
}

.sectionContainer{
    border: 1px solid #ccc;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
}

.filterContainer{
    border: 1px solid #ccc;
    margin-left: 30px !important;
    margin-right: 30px !important;
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
    display: flex;
    align-items: center;
    justify-content: start;
    position: relative;
}

.deleteIconContainer {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.section-divider {
    border: 0;
    height: 1px;
    background-color: #ccc;
    margin-top: 20px;
    margin-bottom: 20px;
}