.tableDisplayDialogTitleBox {
    display: flex;
    flex-direction: row;
}

.tableDisplayDialogTitleBtn {
    align-self: center;
    width: 100%;
}

.tableDisplayDialogBox {
    min-width: 400px !important;
    max-height: 700px !important;
    height: 75% !important;
}