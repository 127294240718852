.status-text {
    margin-top: 1.2%;
    margin-left: 4.2%;
}

.status-field {
    margin-left: 3%;
    margin-top: 1.5%;
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.date-filter {
    width: calc(50% - 50px);
    margin-left: 20px !important;
    margin-right: 20px !important;
    margin-top: 15px !important;
    width: 200px;
}

.filters {
    display: flex;
}

.line-divider {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

@media (max-width: 800px) {
    .date-filter {
        width: calc(50% - 50px);
        margin-left: 5px !important;
        margin-right: 5px !important;
        margin-top: 5px !important;
        width: 49%;
    }
}

@media (max-width: 650px) {
    .filters {
        display: grid;
        width: 100%;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        justify-items: flex-start;
        align-items: center;
        min-height: 85px;
    }
}