.entireBarcodeSubtableContainer {
    display: flex;
    flex-direction: column;
}

.entireBarcodeSubtableRow-outer {
    display: flex;
    flex-direction: column;
}

.entireBarcodeSubtableRow-inner {
    display: flex;
    flex-direction: row;
}

.entireBarcodeSubtableCell {


}

.popoverContent {
    background-color: rgba(97, 97, 97, 0.9);
    padding: 20px;
    border-radius: 8px;
    min-width: 300px;
    color: white;
    font-size: 14px;
}

.popover {
    margin-top: 10px;
}

.entireBarcodeSubtableCell-editingAddButton {
    width: 150px;
    margin-top: 5px;
    margin-bottom: 15px;
}

.barcodeSubtableContainer {
    display: flex;
    flex-direction: column;
}

.barcodeSubtableCell-newBarcode {
    margin-left: 24px;
    margin-bottom: 10px;
}