.package-fields-container {
    display: flex;
    width: 98%;
    margin-top: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.row-flexbox {
    display: flex !important;
    width: auto;
    min-width: 40%;
}

.package-single-line-field {
    margin: 10px !important;
    width: 715px;
}

.input-text-field {
    margin: 10px !important;
    width: 225px
}

.cancel-save-buttons {
    display: flex;
    justify-content: flex-end;

}

.button-spacing {
    margin-right: 10px;
}

.pallete-flute-input-field {
    margin: 10px !important;
    width: 102px
}

.sizing-fields {
    margin: 10px !important;
    width: 100px
}

.sizing-title {
    margin: 10px !important;
    width: 110px
}

.sizing-flexbox {
    display: flex !important;
    align-items: center;
    justify-content: start;
    width: auto;
    min-width: 40%;
}

.buttons-row {
    display: flex !important;
    justify-content: center;
    width: auto;
    min-width: 40%;
}