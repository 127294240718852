
.dashboardMain {
    display: flex;
    background-color: #fff;
}

.dashboardSummary {
    display: flex;
    background-color: #fff;
    margin: 20px;
}

.container {
    background-color: #fff;
    margin: 20px;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.23);
    width: 100%;
}

.dateselectrow {
    background-color: #fff;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 1.3%;
    display: flex;
    justify-content: space-between;
}

.dateLocationBlock {
    display: flex;
    float: right;
}

.dateField {
    margin-right: 2%;
}



.row {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
    justify-content: space-between;
}

.rowPackedList {
    display: flex;
    flex-wrap: wrap;
    margin: 0 20px 20px;
}

.rowCol {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loadingCircle {
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
    justify-content: center;

}

.center {
    width: 100%;
    height: 50.6%;
    padding: 17px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.center-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

}

.rowColPrintedCount {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    text-align: right;
    float: end;
}

.header {
    margin: 10px !important;
    padding-right: 1.5%;
    text-align: left;
}

.underline {
    /*margin: 0 20px 0 0;*/
    /*padding: 0;*/
}

.scanInputs {
    margin: 10px 0 !important;
    padding: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 250px;
    min-height: 200px;
    background-color: white;
    width: 100%;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.23);
}

.packedListInput {
    margin: 10px 0 !important;
    padding: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 250px;
    min-height: 200px;
    background-color: white;
    width: 100%;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.23);
}

.packedInputs {
    margin: 10px 5px !important;
    padding: 0;
    overflow-y: scroll;
    height: 200px;
    min-height: 200px;
    background-color: #F7F7F7;
    width: 200px;
}

.rowScan {
    display: flex;
    width: 400px;
    min-width: 400px;
    flex-wrap: wrap;
    margin: 10px 0 !important;
}

.rowColPacked {
    /*margin-right: 20px;*/
    /*margin-left: 20px;*/
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.scanInput {
    width: 100%;
}

.packedInput {
    /*margin-right: 10px !important;*/
    margin-right: 0 !important;
    width: 49%
}

.packedInput textarea {
    overflow: hidden;
    overflow-wrap: unset;
}

.scanInput textarea {
    overflow: hidden;
    overflow-wrap: unset;
}

.alert {
    margin-right: 10px;
}

.list {
    padding: 0 10px !important;
}

.unpackedList {
    padding: 0 10px !important;
    color: #08a5c7;
}

.locationDateFields {
    width: 200px;
    margin-left: 28px;
    margin-bottom: 10px; 
}

@media screen and (max-width: 1200px) {
    .dashboardMain {
        flex-direction: column;
    }

    .dashboardSummary {
         flex-direction: column;
     }

    .container {
        width: auto;
    }
}

@media screen and (max-width: 800px) {
    .scanInputs {
        width: 100%;
        overflow-x: scroll;
    }

    .packedListInput {
        width: 92%;
        overflow-x: scroll;
        margin-top: 5px;
        /* margin-left: 30px !important; */
    }

    .packedInput {
        width: 270px;
    }

    .timestamp {
        padding-bottom: 10px !important;
    }

    .rowCol {
        width: 78%;
    }

}

@media screen and (max-width: 600px) {
    
    .dayOfWeek {
        order: 2;
        margin: 10px;
        margin-bottom: 0px;
        text-align: center;
    }
    .dateLocationBlock {order: 1;}

    .dateselectrow {
        display: grid;
        grid-template-columns: 100%;
        width: 100%;
        margin: 15px;
        margin-bottom: 0px;
    }

    .dateLocationBlock {
        display: grid;
        width: 100%;
    }

    .locationDateFields {
        width: 100%;
        margin: 10px;
        margin-left: 0px;
        margin-bottom: 0px; 
        margin-right: 10px;
    }

    .dateField {
        margin-top: 10px;
        margin-right: 30px;
    }

    .locationField {
        margin-top: 10px;
        margin-right: 30px;
    }

    .rowColPacked {
        justify-content: center;
    }

    .packedInput {
        margin: 5px !important; 
    }

    .packedListInput {
        width: 87%;
        overflow-x: scroll;
        padding: 0% !important;
    }
}

.cardTotalHeader {
    font-weight: bold;
    font-size: 0.875rem;
    margin-top: 10px;
    margin-bottom: 10px;
}

.pendingCardTitle {
    font-weight: bold;
    font-size: 17px;
    margin-top: 3px;
    margin-bottom: 3px;
}

.cardCellItems {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
}

.tableContainer {
    display: flex;
    flex-direction: column;
    height: 430px;
    overflow: auto;
}

.tooltip {
    margin-bottom: 10px;
}



