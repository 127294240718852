.entireBarcodeSubtableContainer {
    display: flex;
    flex-direction: column;
}

.entireBarcodeSubtableRow-outer {
    display: flex;
    flex-direction: column;
}

.entireBarcodeSubtableRow-inner {
    display: flex;
    flex-direction: row;
}

.entireBarcodeSubtableCell {


}

.entireBarcodeSubtableCell-editingAddButton {
    width: 150px;
    margin-top: 5px;
    margin-bottom: 15px;
}

.barcodeSubtableContainer {
    display: flex;
    flex-direction: column;
}

.barcodeSubtableCell-newBarcode {
    margin-left: 24px;
    margin-bottom: 10px;
}

.listingsVerticallyCenter {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 100%;
}

.listingsHorizontallyCenter {
    width: 100%;
    display: flex;
    justify-content: center;
}

.listingsHorizontalDivider {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #E0E0E0;
}