.searchField {
    background-color: #FFFFFF;
}
.searchField .MuiInputBase-input {
    width: 300px !important;
 }

.custom-search {
    width: 100% !important;
    display: flex;
    align-content: space-between;
}

.auto-search {
    width: 100% !important;
    display: flex;
    align-content: space-between;
}

.MuiOutlinedInput-inputMarginDense {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}

.clear-button {
    width: 30px;
}