
.rowSpec {
    display: flex !important;
    width: 33vw;
}

.rowCentered{
    justifyContent: center;
    alignItems: center;
    width: 33vw;
}

.rowCol {
    display: flex;
    flex-direction: column;
}

.rowSelects {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: start;
}

.rowCarrier {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.innerBox {
    margin-top: 10px;
}

.logoinnerBox {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.formInput {
    margin: 10px 10px 10px 0px !important;
    width: 95%;
}

.formInputsingle {
    margin: 10px !important;
    width: 715px;
}

.formTwoColInput {
    margin: 10px !important;
    width: 33%;
}

.formThreeInput {
    margin: 10px !important;
    width: 33%
}

.formTwoInput {
    margin: 10px !important;
    width: 33%
}

.carrier_stepper_align {
    text-align: center;
}

.carrier_stepper_btn {
    margin: 10px 10px 10px 10px !important;
}

.logoUploadBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.emptyDiv {
    width: 35%;
}

@media screen and (max-width: 600px) {
    .rowSpec {
        display: grid !important;
        width: 100%;
        grid-template-columns: 100%;
        min-width: 40%;
    }
    .formTwoInput {
        margin: 5px !important;
        width: 100%;
    }
    .formThreeInput {
        margin: 5px !important;
        width: 100%;
    }
    .formInputsingle {
        margin: 5px !important;
        width: 100%;
    }
    .formTwoColInput, .formInput {
        margin: 5px !important;
        width: 100%;
    }
}