/* Table Container */
.MuiTable-root-trail {
    border-collapse: collapse;
    width: 100%;
}

/* Table Headers */
.MuiTableHead-root-trail th {
    font-weight: bold;
    background-color: white;
    padding-top: .20rem;
    padding-bottom: .20rem;
    margin: 0;
    text-align: center;
    border-top: 1px solid rgb(224, 224, 224);
}

/* Table Rows */
/*.MuiTableBody-root-trail tr:nth-of-type(odd) {*/
/*    background-color: #f9f9f9;*/
/*}*/

.MuiTableBody-root-trail tr:nth-of-type(even) {
    background-color: white;
}

/* Table Cells */
.MuiTableCell-root-trail {
    padding: 8px 16px;
    text-align: center;
    vertical-align: middle;
    /*border: 1px solid #ddd;*/
}

/* Section Headers */
.section-header-trail {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 10px 0;
}

/* Icons */
.MuiIconButton-root-trail {
    margin: 0 5px;
}

/* Collapsible Section Padding */
.collapsible-section-trail {
    padding: 10px 0;
}

.box-trialBalance {
    gap: 32px;
    display: flex;
    margin-left: 34px;
    margin-bottom: 24px;
    margin-top: 30px;
    padding: 0;
}

.MuiTable-root-trail::-webkit-scrollbar {
    display: none; /* Hides the scrollbar for Chrome, Safari, and Edge */
}
