.addressPopupMain {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
}

.popupTitle {
    padding-left: 35px;
    padding-top: 20px;
}

.errorMessage {
    padding-left: 35px;
}

h4 {
    font-size: 20px;
}

.fullPopupPrompt {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 900px;
    height: 500px;
    background-color: #f9f9f9;
}

.exitBtn {
    position: absolute;
    float: right;
    right: 35px;
    top: 20px
}

.addressPopup {
    display: flex;
    justify-content: center;
}


.addressPopupBodyCheck {
    margin-top: 15px;
}

.addressPopupBody p {
    margin: 0;
    padding: 0;
}


.addressToCheck {
    padding-top: 25px;
    position: absolute;
    left: 35px;
}


.validatedAddress {
    padding-top: 25px;
    position: absolute;
    right: 105px
}


.addressCheckBody p {
    padding-top: 0;
    margin: 0;
}


.options {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 20px;
    margin-bottom: 0;
    padding: 35px 35px 5px;
}


.editBtn {
    margin-top: 40px;
    padding-top: 20px;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.editBtnBtn {
    width: 50px;
}

.simpleOptions button {
    margin-right: 50px;
}

.simpleOptions {
    margin-bottom: 0;
    padding-bottom: 0;
}

.keepBtn {
    width: 250px;
    margin-right: 95px;
}

.changeBtn {
    width: auto;
    margin: 0;
    padding: 6px 9px;
}

.red {
    color: red;
}

.black {
    color: black
}