.scrub-data-container {
    margin: 20px;
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: start;
}

.tooltip {
    margin-bottom: 10px;
}