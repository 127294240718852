.table-container {
    width: 100%;
    overflow-x: auto;
}

.table-wrapper {
    table-layout: fixed;
}

.header-title {
    margin: 15px;
}

.icon-cell {
    width: 90px !important;
    box-sizing: border-box !important;
}

.table-cell {
    width: 200px !important;
    height: 70px !important;
    font-weight: normal !important;
    border-left: none;
    border-right: none;
    text-align: left !important;
    padding: 0.2em !important;
}

.total-cell{
    margin-right: 22%;
    text-align: right;
    font-weight: 600;
    margin-top: 16px;
    margin-bottom: 16px;
}

.transaction-dollar-sign-total {
    padding-right: 7%;
}

.transaction-dollar-amount-total{
    text-align: right;
}