.filterNumericFieldPredefined {
    border: 1px solid #BBBBBB;
    border-radius: 5px;
    margin: 10px;
}

.filterNumericFieldNamePredefined {
    min-width: 150px;
    width: 180px;
    text-align: right;
}

.filterNumericFieldNamePredefinedSecondLine {
    min-width: 150px;
    width: 150px;
    text-align: right;
}

.filterNumericFieldOptionsPredefined {
    min-width: 200px;
    width: 200px;
    height: 40px;
    margin: 10px;
    text-align: left;
}

.filterPredefinedFieldInput {
    min-width: 200px;
    width: 200px;
    height: 40px;
    margin: 10px;
    text-align: left;
}


.filterNumericFieldInputPredefined {
    min-width: 120px;
    width: 120px;
    margin: 10px !important;
    text-align: left;
}

.filterNumericFieldInput .MuiOutlinedInput-input {
    padding-top: 10.5px !important;
    padding-right: 7px !important;
    padding-bottom: 10.5px !important;
    padding-left: 7px !important;
}

.order-item-warning {
    margin-left: 95px;
}