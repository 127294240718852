.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox {
    padding: 0px 12px 0 3px !important;
}
/*.MuiTableCell-root {
    padding-left: 5px !important;
}*/

.paper {
    height: 100% !important;
}

.table th {
    font-weight: 700;
    padding: .20rem !important;
    vertical-align: middle !important;
}

.table td {
    vertical-align: middle !important;
}

/* The toolbar below the main toolbar */
.secondaryBar .MuiFormControl-marginDense {
    margin-top: 0px !important;
    margin-bottom: 4px !important;
    margin-left: 8px !important;
    text-align: left !important;
 }
.secondaryBar .MuiTablePagination-root {
    margin-bottom: 10px !important;
 }
.secondaryBar .MuiTableCell-root {
    width: 400px;
    margin-left: 15px;
    border-bottom: none !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.date-filter {
    width: calc(50% - 50px);
    margin-left: 20px !important;
    margin-right: 20px !important;
    margin-top: 15px !important;
}

.table-pagination{
    border-bottom: none !important;
    /*max-width: 195px !important;*/
    text-align: left;
    overflow: auto !important;
}

.shipment-table-pagination {
    border-bottom: none !important;
    min-width: 415px !important;
    text-align: left;
    min-height: 100% !important;
}

.shipment-table-pagination .MuiTablePagination-toolbar {
    min-height: 64px !important;
}

.shipment-switches {
    display: flex !important;
}

.shipment-TocIcon {
    margin-top: 8px;
    margin-left: 3px;
}

.shipment-fileImportBox {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.shipment-fileImportBtn {
    margin-left: 10px;
    margin-right: 10px;
}

.MuiOutlinedInput-adornedStart {
}

.MuiAutocomplete-inputRoot {
    max-height: 500px !important;
}

.MuiTablePagination-selectRoot {
    margin-right: 15px !important;
}

.MuiTablePagination-actions {
    margin-left: 10px;
}

.incomplete .MuiSwitch-colorSecondary {
    color: #f50057 !important;
}

.incomplete .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #f50057 !important;
}

.not-validated .MuiSwitch-colorSecondary {
    color: #f9e076 !important;
}

.not-validated .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #f9e076 !important;
}

.completed .MuiSwitch-colorSecondary {
    color: #acd685 !important;
}

.completed .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #acd685 !important;
}

.rated .MuiSwitch-colorSecondary {
    color: #ec9f0e !important;
}

.rated .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #ec9f0e !important;
}


.root{
    height: 100%;
}