.filterTagsBar {
    margin-left: 15px;
    display: flex;
    flex-wrap: wrap;
    min-height: 25px;
}

.filterTag  {
    border: 2px solid #acd685 !important; 
    border-radius: 10px !important;
    font-size: 10px !important;
    max-height: 20px;
    white-space: nowrap;
    padding: 0px 8px !important;
    margin-top: 2px;
}

.shipment-tag {
    margin-right: 5px;
}

.filterTag .MuiButton-endIcon {
    font-size: 14px !important; 
    margin-left: 4px !important;
}

.filterTag .MuiButton-iconSizeSmall > *:first-child {
    font-size: 10px;
}