.getAllPrinterBtnBox{
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 20px;

}

.getAllPrinterBtnOuterBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.getAllPrinterBtn{
    width: 250px;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.getPrinterLoadingBox{
    display: flex;
    justify-content: center;
    margin-top: 30px;

}

.getPrinterLoadingOuterBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
