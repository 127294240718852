.edgeContainer {
    width: 100%;
    height: 100%;
    position: relative;
}

.leftEdge, .rightEdge, .topEdge, .botEdge, .topLeftCorner, .topRightCorner, .botLeftCorner, .botRightCorner {
    position: absolute;
}

.leftEdge, .rightEdge {
    height: 100%;
    top: 0px;
    cursor: ew-resize;
}
.topEdge, .botEdge {
    width: 100%;
    left: 0px;
    cursor: ns-resize;
}

.leftEdge {
    left: 0px;
}
.rightEdge {
    right: 0px;
}

.topEdge {
    top: 0px;
}
.botEdge {
    bottom: 0px;
}

.topLeftCorner {
    left: 0px;
    top: 0px;
    cursor: nwse-resize;
}
.topRightCorner {
    right: 0px;
    top: 0px;
    cursor: nesw-resize;
}
.botLeftCorner {
    left: 0px;
    bottom: 0px;
    cursor: nesw-resize;
}
.botRightCorner {
    right: 0px;
    bottom: 0px;
    cursor: nwse-resize;
}

.labelRow {
    display: flex;
    position: absolute;
    left: -2px;
    top: -22px;
    border: 2px dashed #acd685;
    width: calc(100% + 4px);
}
.subFieldLabelRow {
    display: flex;
    position: absolute;
    left: -2px;
    top: -20px;
    border-width: 2px;
    border-color: #acd685;
    width: calc(100% + 4px);
}
.boxLabel {
    padding-right: 5px;
    font-size: 12px;
    margin: 0px;
    padding-left: 5px;
    width: 100%;
    color: black;
    border-right: 2px dashed #acd685;
    background: white;
    font-weight: 500;
}
.resetButton {
    color: red;
    height: 18px !important;
    cursor: pointer;
    background: white;
}

.menuHolder {
    padding: 7px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 26px;
    border: 1px solid black;
    visibility: visible !important;
    width: 40px;
    display: inline-block;
}

.sidebarHolder {
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: -3px 3px 20px 0px;
    background: white;
    height: 100%;
}

.selectAreaHolder {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
}
.horizontalLine {
    height: 5px !important;
    color: black !important;
    background-color: black !important;
}

.selecting {
    cursor: crosshair;
}

.label {
    z-index: 10000000;
    position: absolute;
    background: white;
    border: 1px solid black;
    padding-left: 5px;
    padding-right: 5px;
}

.zoom-box {
    display: flex;
    align-items: center;
}

.dateField .MuiInputBase-input {
    height: 0px !important;
}