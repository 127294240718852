.invoice-item {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.invoice-item-container{
    margin: 10px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    padding: 10px 20px;
    width: 90%;
}

.header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.header-title {
    display: flex;
    align-items: center;
    flex: 1;
}

.icons {
    margin-right: 10px;
}

.invoice-number {
    margin-top: 5px;
    margin-left: 35px;
}

.error-message{
    margin-top: 5px;
    margin-left: 35px;
    font-weight: bold;
}

.invoice-item-information {
    display: flex;
    align-items: start;
    justify-content: space-evenly;
    margin-top: 5px;
}

.invoice-item-details-container {
    flex: 1;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    min-height: 700px;
}
.invoice-item-details-half-split-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 720px;
}

.sender-recipient-information {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    min-height: 340px;
}

.invoice-item-details {
    display: flex;
}

.invoice-item-details-header {
    flex: 1;
}

.invoice-item-details-value {
    flex: 1
}

.invoice-item-charges-value {
    text-align: right;
}

.invoice-item-total {
    font-weight: bold;
    margin-bottom: 15px;
}