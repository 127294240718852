.shortcode {
    /*height: 0;*/
    width: 10%;
    text-align: center;
}

.description {
    width: 35%;
    text-align: center;
}

.partNumberCol {
    width:10%;
    text-align: center;
}

.add-delete-col {
    width:5% !important;
}

.center {
    text-align: center;
}

.check {
    column-span: 5;
    width:4%;
    border-bottom: 0px !important;
}

.notes {
    display: flex;
    align-items: center;
    margin-bottom: 0.8%;
}

.supplier-select {
    float: left;
    /* margin-left: 17px; */
    
    width: 225px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
}

.pick-date {
    margin-left: 10px;
    width: 200px;
    /* margin-bottom: 10px; */
}

.preview-button {
    margin-left: 2% !important;
}

.cancel-button {
    margin-left: 1% !important;
}

.cancel-preview-buttons {
    display: flex;
    justify-content: center;
}
.preview-button-div {
    display: flex;
    justify-content: center;
}

.location-field {
    float: left;
    height: 50px;
    width: 150px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.quantity-cell {
    width: 9%;
    margin-right: 20px;
    min-width: 50px;
}

.discount-cell {
    width: 9%;
}

.pricing-cell {
    width: 11%;
}

.partnum-cell {
    width: 9%;
}

@media (max-width: 800px) {
    .quantity-cell {
        width: 50px;
        padding: 5px !important;
    }
}

@media (max-width: 600px) {
    .location-field {
        float: left;
        width: 100%;
        margin: 10px;
    }
    .pick-date {
        margin-left: 10px;
        width: 80%;
        /* margin-bottom: 10px; */
        margin: 10px;

    }
    .customer-select {
        float: left;
        width: 100%;
        margin: 10px;
        margin-top: 0px;
    }
}
