.mainChart{
    margin-top: 25px;
    margin-bottom: 30px;
    margin-left: 30px;

}
.subChart{
    margin-left: 30px;
}

.hidebutton{
    margin-left: 3px;
    margin-bottom: 25px;
}

.notesContainer{
    margin-bottom: 30px;
    margin-top: 15px;
    width: 100%;
}

.pricingDashboardSidebarHolder {
    position: absolute;
    top: 140px;
    right: 20px;
    box-shadow: -3px 3px 20px 0px rgba(0,0,0,0.2);
    background: white;
    height: calc(100% - 140px);
    transition: width 0.3s ease-in-out;
    overflow: auto;
    z-index: 1000;
}

.editRelatedProductTitle {
    margin-top: 20px;
    margin-left: 15px !important;
    z-index: 20;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
}

.editRelatedProductSubTitle {
    margin-left: 15px !important;
    z-index: 20;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
    font-size: 15px;
    text-align: left;
}

.sortingToggle{
    margin-left: 5px !important
}

.relatedProductTextField{
    border: 1px solid #BBBBBB;
    border-radius: 5px;
    margin: 10px;
    height: 130px;
}

.actionTextFieldInput {
    min-width: 180px;
    width: 200px;
    margin: 10px !important;
    height: 40px;
    text-align: left;
}