.invoiceWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 70px;
}

.infoRow {
    text-align: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.leftBox {
    text-align: center;
    width: 35%;
}

.mainContent {
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;
}

.rightBox {
    margin-top: 10px;
    text-align: center;
    width: 60%;
}

.divider {
    width: 95%;
    display: inline-block;
}

.paymentMethodSection {
    text-align: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.needs-review-container {
    background-color: #fff;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.23);
}

.errorMessage-content {
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
}