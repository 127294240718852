.product-fields-container {
    display: flex;
    width: 98%;
    margin-top: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.row-flexbox {
    display: flex !important;
    width: auto;
    min-width: 40%;
}

.product-single-line-field {
    margin: 10px !important;
    width: 715px;
}

.input-text-field {
    margin: 10px !important;
    width: 225px
}

.input-weight {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-left: 10px !important;
    width: 107px;
}

.input-weight-unit {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-left: 11px !important;
    width: 107px;
}

.cancel-save-buttons {
    display: flex;
    justify-content: flex-end;

}

.button-spacing {
    margin-right: 10px;
}

.boxes-list {
    padding-left: 40px;
}
.suggestion {
    padding-left: 20px;
}

.box-entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.firstBox {
    width: 225px;
    max-width: 225px;
}

.autocompleteBox {
    width: 100%;
}


@media screen and (max-width: 600px) {
    .row-flexbox {
        display: grid !important;
        width: 100%;
        grid-template-columns: 100%;
        min-width: 40%;
    }
    .product-single-line-field, .input-text-field {
        margin: 5px !important;
        width: 100% !important;
    }
}