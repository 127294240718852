.comments-container {
    /* width: 1045px; */
    width: 100%;
    margin: 10px 0px 10px 0px;
}

.comments-container .MuiTableCell-root {
    /*padding-left: 10px !important;*/
}

.date-user-heading-cell {
    border-bottom: none !important;
    border-right: none !important;
    width: 10% !important;
}

.comment-heading-cell {
    border-bottom: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.comment-bottom-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.comment-left-border {
    border-left: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.comment-right-border {
    border-right: 1px solid rgba(0, 0, 0, 0.23) !important;
}


.last-row-cells {
    border-bottom: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.no-text-wrap {
    white-space: nowrap;
    text-align: right !important;
}


.no-bottom-border {
    border-bottom: none !important;
}

.add-delete-cell {
    text-align: right;
}

.comments-field {
    width: 100%;
}

.comment-table {
    border-radius: 5px;
}