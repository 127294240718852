.location-select {
    margin-left: 55%;
}

.search-filter-date-container {
    padding-top: 1%;
    display: flex;
    flex-wrap: wrap;
    width: 97%;
    position: relative;
    margin-bottom: 2%;
}

.shortcode-search {
    margin-left: 2%;
}

.date-range-select {

}

.date-header {
    margin-left: 3% !important;
    margin-top: 0.5% !important;
}

.filter-tag-wrapper {
    padding-top: 0% !important;
    width: 100%;
}