.automationRulesPaper {
    width: 70%;
    display: inline-block;
    margin-bottom: 75px;
}

.fieldsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ruleGroupField {
    margin: 20px !important;
    width: 28%
}

.div {
    width: 65%;
    display: inline-flex;
}

.divider {
    width: 95%;
    display: inline-block;
}

.automation-rules-margin {
    margin: 20px !important;
}

.infoTooltipIcon {
    padding-left: 10px;
    float: left;
}

.MuiTooltip-tooltip {
    padding: 8px 16px !important;
    font-size: 13px !important;
}

.MuiTooltip-touch {
    font-weight: 500 !important;
    line-height: 1.4em !important;
}

.exitContinueSwitch {
    padding-top: 3px;
    padding-left: 5px;
    float: left;
}

.anyAllSwitch {
    padding-left: 50px;
    margin-bottom: -15px !important;
    float: left;
}

.automationRulesFilterButton {
    width: 125px;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.automationRulesActionButton {
    width: 125px;
    margin-top: 20px !important;
}

.automationRulesConditionButton {
    width: 125px;
    margin-bottom: 20px !important;
}

.automationRulesStepper {
    text-align: center;
    background-color: #FFFFFF;
}

/**
 * Toolbar for the AutomationRuleCondition
 */
.conditionsToolbar {
    background: #acd685;
    min-height: 30px !important;
    padding-right: 16px !important;
}

.conditionsToolbarDiv {
    /* a div within the toolbar becomes necessary to add in order to float the icons to the right */
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
}

.conditionIcons {
    float: right;
    padding: 0px !important;
}

.upArrow {
    padding-left: 10px !important;
}

.copyButton {
    padding-right: 25px !important;
}