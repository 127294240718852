.centre-cell {
    text-align: center;
}

.bottom-wrapper {
    width: 100%;
    margin-top: 1%;
}

.submit-button {
    float:right;
    margin-right: 1% !important;
}

.cancel-button {
    margin-right: 10px;
    margin-bottom: 10px;
    float: right;
}

.no-border {
    border-bottom: none;
}

.top-border {
    border-top-style: solid;
}

.left-border {
    border-left-style: solid;
}

.right-border {
    border-right-style: solid;
}

.no-border {
    border-bottom: none !important;
}

.center {
    text-align: center !important;
}

.expanding-row {
    padding-bottom: 0 !important;
}

.shrink-qty-date-field {
    width: 114px;
}

.expanded-row {
    padding: 0 !important;
}

/*.MuiIconButton-root {
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 0 !important;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}*/

.received-to-date-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: 50px 100px 50px;
    grid-template-rows: auto auto auto;
    align-items: center;
}

.qty-received {
    text-align: right;
}

.date-received {
    text-align: right;
}

.delete-icon {
    margin-bottom: 1px;
}

.cellPadding {
    padding: 5px !important;
}