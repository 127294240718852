

.layout-container {

}

.layout-container-flexible {
    width: 100%;
}

.row-container {
    display: flex;
}

.row-element-container {
    margin: 10px;
}

.checkbox-container {

}

.margin-10px {
    width: 10px;
}

.margin-20px {
    width: 20px;
}













