.rbc-header {
    background: #005E73;
    color: #FFFFFF;
    padding: 4px;
}

.rbc-event {
    background: #FFFFFF !important;
    color: #005E73;
    padding: 0;
}

.rbc-day-bg {
    background-color: white !important;
}



.event-table-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
}

.event-table {

    flex: 1 1 300px;
    overflow-x: auto;
}

.event-table h2 {
    text-align: center;
}

.event-table table {
    width: 100%;
    border-collapse: collapse;
}


.event-table th {
    /*background-color: #f2f2f2;*/
    border-top: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.event-table td {
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.event-table tr:nth-child(even) {
    /*background-color: #f2f2f2;*/
}

.event-table tr:hover {
    /*background-color: #ddd;*/
}

@media (max-width: 768px) {
    .event-table {
        flex-basis: 100%;
    }
}

.right-align {
    text-align: right !important;
}