.file-upload-button {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.file-upload-field {
    width: 100%;
    text-align: center;
}

.file-chips {
    display: flex;
    text-align: center;
    flex-direction: column;
}

.hidden-file-field {
    display: none;
}

.multi-file-container {

}

.single-file-container {
    display: flex;
    position: relative;
    border: 1px solid;
    border-radius: 3px;
}

.single-file-container-borderless {
    display: flex;
    position: relative;
}

.packing-slip-file-container {
    display: flex;
    position: relative;
}

.file-upload-container {
}

.add-single-file-button {
    text-align: left;
    margin-top: 1%;
}

.single-file-chip {
    margin-top: 3%;
}

.ftl-file-chip {
    margin-top: 2%;
}

.packing-slip-chip {
    margin-top: 3%;
    display: flex;
    flex-direction: row;
}

.vertical-file-chips {
    margin-bottom: 1%;
}