
.shipment-form {
    display: flex;
}

.item-detail-border {
    border: 1px solid #92C95F;
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-radius: 4px !important;
}

.commodity-item-detail-border {
    border: 1px solid #92C95F;
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-radius: 4px !important;
    width: 100%;
    /*margin-left: -20px;*/
}

.input-form {
    margin-left: 20px;
}

.title-commodity {
    display: block;
    margin: 10px 20px 0px 0px !important
}

.input-form-commodity {
    margin-left: 0px;
    /*overflow-y: scroll;*/
    max-height: 365px !important;
    min-height: 365px !important;
    height: 365px !important;
}

.commodity-loading-box {
    margin-left: 0px;
    overflow-y: scroll;
    max-height: 365px !important;
    min-height: 365px !important;
    height: 365px !important;
    text-align: center;
}

.input-form-order {
    margin-left: 20px;
    overflow-y: scroll;
    max-height: 401px !important;
    padding-bottom: 10px !important;
}

.input-form-cloned-order {
    margin-left: 20px;
}

.input-form-package {
    margin-left: 0;
    /*overflow-y: scroll;*/
    /*max-height: 531px !important;*/
    min-height: 531px !important;
    /*height: 531px !important;*/
}

.input-control {
    margin: 10px !important;
    min-width: 400px !important;
}

.row-spec {
    display: flex !important;
    width: auto;
    min-width: 40%;
}

.package-rate {
    text-align: left !important;
    justify-content: center;
    padding-left: 15px;
}

.package-rate-tracking-open {
    text-align: left !important;
    justify-content: center;
    padding-left: 15px;
    padding-top: 3px;
}

.add-tracking-box {
    display: flex;
    justify-content: space-between;
    width: 380px;
}

.row-spec-package {
    display: flex !important;
    width: auto;
    min-width: 380px !important;
    max-width: 380px !important;
}

.row-col {
    display: flex;
    flex-direction: column;
}

.shipment-form-title-row {
    display: flex;
    width: auto;
    flex-direction: row;
    min-width: 40%;
}

.form-header {
    margin: 10px !important;
    padding-right: 1.5%;
    text-align: left;
}

.suggested-box-type-header {
    margin: 10px 20px 0px 10px !important;
    padding-right: 1.5%;
    text-align: left;
}

.from-select {
    margin: 10px !important;
    width: 300px !important;
}

.header-package {
    margin: 10px 20px 0 20px !important;
}

.package-tab {
    min-width: 100px !important;
    max-width: 100px !important;
    font-weight: bold !important;
    color: black;
    border: 2px solid #acd685 !important;
    border-radius: 4px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: none !important;
    background-color: #92C95F !important;
    outline: none !important;
}

.package-tab-not-selected {
    min-width: 100px !important;
    max-width: 100px !important;
    font-weight: bold !important;
    color: black;
    border: 2px solid #eaeaea !important;
    border-radius: 5px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: none !important;
    outline: none !important;
}

.input-spec {
    margin: 10px !important;
    max-width: 225px;
    min-width: 225px;
    width: 100%;
}

.input-specl {
    margin: 10px !important;
    width: 470px;
    padding-bottom: 10px;
}

.input-specl-label{
    margin-top: 7px !important;
}

.input-spec-single {
    margin: 10px !important;
    width: 97%;
    max-width: 715px;
}

.input-spec-two-col {
    margin: 10px !important;
    max-width: 470px;
    width: 97%;
}

.input-spec-commodity {
    margin: 10px !important;
    width: 220px
}

.input-spec-international-first-col {
    margin: 10px 10px 10px 0px !important;
    width: 220px
}

.input-spec-extra-commodity {
    margin: 10px 10px 10px 0px !important;
    width: 220px
}

.input-spec-commodity-two-col {
    margin: 10px !important;
    width: 580px;
}

.input-spec-extra-commodity-two-col {
    margin: 10px 10px 10px 0px !important;
    width: 580px;
}

.input-spec-commodity-one-half-col{
    margin: 10px !important;
    width: 340px;
}

.input-spec-commodity-half-col {
    margin: 10px !important;
    width: 100px;
    text-align: center;
}

.input-spec-commodity-first-col {
    margin: 10px 10px 10px 0px !important;
    width: 100px;
    text-align: center;
}

.commercial-invoice-box {
    /*margin: 10px !important;*/
    /*width: 100%;*/
    text-align: right;
    float: right;
}

.input-dim {
    margin: 10px !important;
    width: 80px !important;
}

.input-dv {
    margin: 10px !important;
    max-width: 170px !important;
    width: 100%;
}

.input-ref {
    margin: 10px !important;
    max-width: 360px !important;
    width: 100%;
}

.package-trackingNumber-ref {
    margin: 10px !important;
    width: 360px !important;
}

.input-control-package {
    /* width: 360px !important;
    min-width: 360px !important; */
    width: 97%;
    max-width: 360px !important;
    margin: 10px !important;
}

.product-list {
    max-width: 488px !important;
    width: 100%;
}

.product-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.select-list .MuiSelect-Select {
    width: 80px;
    max-width: 80px;
}

.menu-select {
    max-width: 80px;
    margin-left: 10px;
    width: 40%;
}

.order-details {
    display: flex;
    width: 100%;
}

.product-table {
    width: 97.5%;
    margin-right: 10px !important;
    column-span: 4;
    display: block;
}

.order-table-row {
    margin-bottom: 10px !important;
    display: flex;
}

.shipment-tag-box {
    display: flex;
    width: 225px;
}

.shipment-tagbar {
    margin-left: 15px;
    align-content: flex-start;
    margin-top: 10px;
    min-width: 31%;
    max-width: 31%;
}

.column-1 {
    width: 500px;
    padding-left: 5px;
    padding-right: 30px;
}

.column-2 {
    /* width: 20%; */
}

.column-3 {
    text-align: right;
    padding-right: 1vw;
}

.column-4 {
    text-align: right;
    white-space: nowrap;
}
.quantityClass{
    
}
.description-col {
    width: 70%;
}

.quantity-col {
    width: 12%;
}

.price-col {
    width: 4%;
}

.top-row-border {
    border: 2px solid rgb(213, 213, 213);
    border-bottom: none !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
}

.bottom-row-border {
    border: 2px solid rgb(213, 213, 213);
    border-top: none !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.product-sku-field {
    max-width: 360px;
    width: 60%;
}

.trackingNumberField {
    display: flex !important;
    width: auto;
    min-width: 40%;
}

.quantity-select {
    max-width: 130px;
    width: 130px;
}

.notes-field {
    width: 97%;
    margin: 10px !important;
}

.box-suggestion-flexbox {
    display: flex !important;
    width: auto;
    flex-direction: row;
}

.highlighted-text {
    background-color: #f5f883;
    font-weight: bold;
    border-radius: 5px;
    padding: 2px;
}

@media screen and (max-width: 1600px) {
    .shipment-form {
        flex-direction: column;
    }
}

@media screen and (max-width: 800px) {
    .shipment-form {
        width: 95%;
    }
    .description-col {
        width: 70%;
    }
.input-spec{
    max-width: none;
}

}

@media screen and (max-width: 650px) {
    .shipment-form {
        width: 100%;
    }

    .row-spec {
        display: grid !important;
        grid-template-columns: 100%;
        width: 100%;
        justify-content: center;
    }
    
    .row-spec-package {
        display: flex !important;
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
    }
    
    .row-col {
        display: flex;
        flex-direction: column;
    }

    .trackingNumberField {

    }

    .input-dim {
        width: 25% !important;
    }

    .input-dv {
        margin: 10px !important;
        width: 100% !important;
    }

    .input-ref {
        margin: 10px !important;
        width: 94% !important;
    }

    .input-form-package {
        margin-left: 0;
        /*overflow-y: hidden;*/
        max-height: 800px !important;
        min-height: 740px !important;
        height: 740px !important;
    }

    .input-control-package {
        width: 94% !important;
    }

    .input-spec-single, .input-spec-two-col {
        margin: 10px !important;
        width: 94%;
        max-width: 715px;
    }

    .input-spec {
        width: 94%;
        max-width: none;
    }

    .package-trackingNumber-ref {
        width: 50% !important;
    }

    .product-container {
        display: flex;
        justify-content: start;
        width: 62%;
    }
    .quantity-select {
        width: 96px;
    }
    .description-col {
        width: 80%;
    }
    .notes-field {
        width: 94%;
        margin: 10px !important;
    }
}

/* @media screen and (max-width: 400px) {
    .shipment-form {
        width: 70%;
    }
} */