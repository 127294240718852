.statusText {
    text-align: center;
    padding: 20px;
    font-size: 24px;
    border-radius: 10px;
    border: 1px solid red;
    width: 100%;
}
.infoRow {
    text-align: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.divider {
    text-align: center;
    width: 100%
}
.lineItemRow {
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
}
.attachmentsRow {
    display: flex;
    align-items: flex-start;
    align-self: start;
    margin-bottom: 25px;
}
.discountPercentage {
    display: flex;
    align-self: end;
}
.notesAndTotals {
    text-align: center;
    width: 100%;
    display: flex;
    padding-bottom: 20px;
}
.invoiceNotes {
    text-align: left;
    width: 100%;
    padding-right: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.totals {
    text-align: center;
    width: 20%;
    display: flex;
    flex-direction: column;
}
.invoiceWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 70px;
}

.needs-review-container {
    background-color: #fff;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.23);
}

.statusText-content {
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
}

.supplier-payment-popper-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.supplier-payment-popper-item {
    margin-bottom: 15px;
}

.supplier-payment-popper-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}