.split-table {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.table-header {
    position: sticky;
    width: 100%;
}

.table-body {
    width: 100%;
    overflow: auto !important;
    max-height: 80%;
}

.table-footer {
    position: sticky;
    width: 100%;
    height: 10%;
}

.footer-content {
    padding-left: 40%;
}

.location-box {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 20px;
}

.country-box {
    width: 25%;
    max-width: 18%;
    padding: 20px 0px 20px 20px;
}

.company-box {
    width: 25%;
    max-width: 18%;
    padding: 20px 20px 20px 0px;
}

.edit-col {
    width: 5%;
}

.id-col {
    width: 30%;

}

.status-col {
    width: 40%;
}

.delete-col {
    width: 15%;
}

.button-box {
    align-items: flex-end !important;
    height: 10%;
    padding-top: 20px;
}

.full-table {
    max-height: 600px !important;
}

.radio-set {
    padding-left: 40px;
    padding-top: 10px;
}