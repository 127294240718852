/* The toolbar below the main toolbar 
 * Used in: AutomationRulesTable, ConditionFiltersTable
 */
.secondaryToolbar .MuiFormControl-marginDense {
    margin-top: 0px !important;
    margin-bottom: 4px !important;
    margin-left: 8px !important;
 }
.secondaryToolbar .MuiTablePagination-root {
    margin-bottom: 10px !important;
 }
.secondaryToolbar .MuiTableCell-root {
    width: 400px;
    margin-left: 15px;
    border-bottom: none !important;
    padding-top: 15px !important;
    padding-bottom: 10px !important;
}

.secondaryToolbar2 .MuiFormControl-marginDense {
    margin-top: 0px !important;
    margin-bottom: 4px !important;
 }
.secondaryToolbar2 .MuiTablePagination-root {
    margin-bottom: 10px !important;
 }
.secondaryToolbar2 .MuiTableCell-root {
    width: 400px;
    border-bottom: none !important;
    padding-top: 15px !important;
    padding-bottom: 10px !important;
}
