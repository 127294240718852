.table-cell-icon {
    width: 225px !important;
    max-width: 225px !important;
}

.marketplace-divider{
    border-bottom: 1px solid rgb(224, 224, 224);
}

.settings-table-cell {
    width: 200px !important;
    height: 70px !important;
    font-weight: normal !important;
    border-left: none;
    border-right: none;
    text-align: left !important;
    padding: 0.2em !important;
}

.table-cell-center {
    text-align: center !important;
    padding: 3px !important;
}

.table-cell-left {
    text-align: left !important;
    padding: 3px !important;
}

.table-cell-right {
    text-align: right !important;
    padding: 3px !important;
}

.table-cell-center-thin {
    text-align: center !important;
    padding: 3px !important;
    width: 130px !important;
}

.import-popper-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.import-popper-item {
    margin-bottom: 15px;
}

.loading-animation {
    animation: spin 1s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.transaction-form {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.transaction-form-section {
    margin-bottom: 30px;
}

.transaction-form-details {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    justify-content: space-between;
    gap: 15px;

}

.transaction-form-details > * {
    width: 250px;
}

.transaction-form-notes {
    width: 100%;
    margin-top: 15px;
}

.transaction-form-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.files-display {
    margin-bottom: 30px;
}

.pdf-icon {
    display: inline-block;
    width: 14%;
}

.tooltip-transaction {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    margin-bottom: 5px;
}

.tax-info-row-tooltip-transaction {
    display: flex;
    justify-content: space-between; /* Align items to the left and right */
    width: 100%; /* Ensure it takes the full width */
}

.tax-info-tooltip-transaction {
    text-align: left; /* Left align province and tax type */
}

.tax-amount-tooltip-transaction {
    margin-left: 10px;
    text-align: right; /* Right align tax amount */
}
