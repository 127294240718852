.margin {
    margin: 20px !important;
    text-align: center;
    max-width: 150px;
    float: left;
}

.other-margin {
    margin: 20px;

    text-align: center;
    max-width: 175px;
    float: left;
    alignItems: center;
}

.other-cost-margin {
    text-align: center;
    max-width: 175px;
    float: left;
    alignItems: center;
    margin-top: 20px !important;
}

.smaller-font {
    font-size: 17px;
}

.signs {
    font-size: 55px;
    float: left;
}
.profitStyle {
    font-size: 35px;
    margin: 20px;
    margin-left: 34px;


}
.currencyStyle {
    font-size: 16px;
    font-weight: bold;
    margin: 20px;
    margin-left: 34px;
 }

.equalSign {
    font-size: 55px;
}

.swap-icon {
    margin: 20px !important;
}

.tick-box{
    float: left;
    margin-top: 23px;
    margin-left: 20px !important;
}

.otherCosts{
    marginBottom: "5px";
    maxWidth: "150px";
    minWidth: "150px";
}

.date-box{
    max-width: 175px;
    min-width: 175px;
    margin-top: 10px;
}

.vl {
    border-left: 2px solid gray;
    margin-left: 80px;
    height: 20px;
}

.price-label {
    margin-left: 38px;
    border: white;
}

.product-label {
    margin-left: 14px;
    border: white;
}

.shipping-label {
    margin-left: 7px;
    border: white;
}

.box-label {
    margin-left: 26px;
    border: white;
}

.referral-label {
    margin-left: 14px;
    border: white;
}

.page {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.columnBox {
    display: flex;
    flex-direction: column;
    margin-left: 34px;
}

.rowBox {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.currency-box {
    padding-right: 0;
}

.removePadding .MuiOutlinedInput-adornedEnd {
    padding-right: 0 !important;
}