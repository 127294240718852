
.mappingSelections {
    padding: 10px 50px 10px;
}

.skuSelections {
    display: flex;
    flex-direction: column;
}

.skuList {
    min-width: 200px;
    width: 200px;
    margin: 20px 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sku-label {
    width: 300px;
}