.clearFiltersButton {
    float: right;
}

.firstRowLeftColumn {
    width: calc(50% - 25px);
}

.leftColumn {
    width: calc(50% - 25px);
    margin-top: 15px !important;
    display: inline-block;
}

.dateRowFlexBox {
    width: 100%;
    display: flex;
}

.dateRowContainer {
    flex-grow: 1;
    display: flex;
    margin-top: 15px !important;
}

.dateRowSpacer {
    width: 15px;
}

.dateRowElement {
    flex-grow: 1;
    display: inline-block;
}

.fullWidthColumn {
    margin-top: 15px !important;
}

.firstRowRightColumn {
    width: calc(50% - 25px);
    margin-left: 50px !important;
}

.rightColumn {
    width: calc(50% - 25px);
    margin-left: 50px !important;
    margin-top: 15px !important;
}

.firstOneThirdColumn {
    width: 100%;
    margin-top: 15px !important;
    display: inline-block;
}

.oneThirdColumn {
    width: 100%;
    margin-left: 12px !important;
    margin-top: 15px !important;
}

.selectField {
    margin-top: 31px !important;
}

.country_selectField {
    width: 100%;
    margin-left: 25px !important;
    margin-top: 15px !important;
    display: inline-block;
}

.country_selectField_label{
    
    margin-top: 16px !important;
}

.filter-action-buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 15px;
}