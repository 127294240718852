.location-form {
    width: 31%;
    margin: 0 auto;
}

.save-cancel-buttons {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 20px;
}

.save-spacing {
    margin-left: 5px;
}

.address-line-1-field {
    width: 98% !important;
}