.actionDropdownSelect {
    border: 1px solid #BBBBBB;
    border-radius: 5px;
    margin: 10px;
}

.predefined-dropdown-shipping-template {
    min-width: 400px;
    width: 468px;
    height: 40px;
    margin: 10px;
    text-align: left;
}
.predefined-dropdown-bank-account{
    min-width: 320px;
    width: 320px;
    height: 40px;
    margin: 10px;
    text-align: left;
}
.actionDropdownSelectName {
    min-width: 250px;
    width: 250px;
    text-align: right;
}

.actionDropdownSelectOptions {
    min-width: 234px;
    width: 234px;
    height: 40px;
    margin: 10px;
    text-align: left;
}

.actionDropdownSelectOptionsForSetShippingAccount {
    min-width: 478px;
    width: 478px !important;
    height: 40px;
    margin: 10px;
    text-align: left;
}

.actionDropdownEmptyDiv {
    width: 100%;
    text-align: right;
}

.actionDropdownEmptyDiv_WithCheckBox {
    width: 80%;
    text-align: right;
}