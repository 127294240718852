.filterBooleanSelect {
    border: 1px solid #BBBBBB;
    border-radius: 5px;
    margin: 10px;
}

.filterBooleanSelectName {
    min-width: 250px;
    width: 250px;
    text-align: right;
}

.filterBooleanSelectOptions {
    min-width: 234px;
    width: 234px;
    height: 40px;
    margin: 10px;
    text-align: left;
}

.filterBooleanEmptyDiv {
    width: 100%;
    text-align: right;
}