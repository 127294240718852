.ftl-setup-grid {
    text-align: center;
    margin: auto;
    width: 70%;
    padding-top: 15px;
}

.ftl-setup-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}

.ftl-attachment-shortcode-field {
    width: 100%;
    text-align: center;
    align-self: center;
    display: inline-block;
}

.add-delete-col {
    width:5% !important;
}