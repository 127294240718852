.baseContainer {

}
.rightContainer {
    width: calc(100% - 240px);
    z-index: 5;
    background-color: white;
}

.leftContainer {
    max-width: 240px;
    position: relative;
}

.fullWidthContainer {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1 !important;
}

.custom-container-fluid {
    margin-right:auto;
    margin-left:auto;
    display: flex;

}

.drawer-menu {
    position: absolute;
    width: 50%;
    z-index: 1000 !important;
    height: 100%;
}