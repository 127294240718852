.searchField {
    background-color: #FFFFFF;
    margin-top: 5px;
}
.searchField .MuiInputBase-input {
    width: 300px !important;
 }
 .filterButton {
     margin-left: 5px !important;
     height: 46px;  
 }