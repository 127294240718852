.supplier-stepper-align {
    text-align: center;
}

.stepper-page-flex {
    display: flex;
    flex-direction: column;
}

.field-spacing {
    margin-bottom: 25px;
}


.stepper-page-one-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.supplier-stepper-page-two-grid {

    display: grid;
    justify-content: center;
    grid-template-columns: 250px 300px 300px 300px 300px;
    grid-template-rows: auto auto auto auto;
    grid-row-gap: 12px;
    justify-items: center;
    align-items: center;
}

.supplier-stepper-page-two-first-grid {

    display: grid;
    justify-content: center;
    grid-template-columns: 250px 300px 300px 300px 300px;
    grid-template-rows: auto auto auto auto;
    justify-items: center;
    align-items: center;
}

.stepper-page-three-grid {

    text-align: left;
    padding-left: 100px;
}

.queryType {
    width: 70%;
    display: inline-block;
    margin-bottom: 75px;
    text-align: center;
}

.query-text-field {
    width: 95%;
}

.conditionsToolbar {
    background: #acd685;
    min-height: 30px !important;
    padding-right: 16px !important;
}

.rowSpecSuppliers {
    display: flex !important;
    width: auto;
    min-width: 40%;
}

.single-line-field {
    margin: 10px !important;
    width: 715px;
}

.single-line-toggle {
    margin: 10px !important;
    width: 225px;
}

.supplier-input-text-field-large {
    margin: 10px !important;
    width: 470px;
}

.row-divider {
    margin: 10px !important;
}

.supplier-input-text-field {
    margin: 10px !important;
    width: 225px;
}

.supplier-delete-row-step-one {
    display: flex;
    justify-self: flex-start;
}

.supplier-execute-invoice-query-button {
    margin: 20px !important;
    align-self: center;
    padding-left: 60px;
}
.supplier-save-invoice-query-button {
    margin-left: 20px !important;
    margin-bottom: 20px !important;
}

@media screen and (max-width: 600px) {
    .stepper-page-one-grid {
        display: grid !important;
        width: 100%;
        grid-template-columns: 100%;
        min-width: 40%;
    }
    .supplier-stepper-page-two-grid {
        grid-template-columns: 5% 35% 30% 30%;
    }
    .single-line-field, .supplier-input-text-field {
        margin: 5px !important;
        width: 100% !important;
    }
}
.logoinnerBox {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.logoUploadBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}