.search-field-container {
    display: flex;
}

.search-bar {
    margin-left: 3% !important;
    margin-top: 3% !important;
    margin-bottom: 2% !important;
    width: 300px !important;
}

.filter-by-mine-checkbox {
    margin-left: 7%;
    margin-top: 3.5%;
}

.cellPadding {
    padding: 5px !important;
}