.create-adjustment-container {
    text-align: center;
    justify-content: center;
}

.to-location {
    width: 250px !important;
    margin-right: 0 !important;
    margin-bottom: 2% !important;
    margin-left: 0 !important;
}


.shortcode-input {
    margin-bottom: 2% !important;
}

.create-adjustment-input-outer-container {
    text-align: center;
    justify-content: center;
    display: flex;
}

.create-adjustment-input-container {
    max-width: 700px !important;
}