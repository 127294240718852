

.quantityBarcodesSubtable-container {
    display: flex;
    flex-direction: column;
}

.quantityBarcodesSubtable-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.quantityBarcodesSubtableEditing-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
}

.quantityBarcodesSubtable-quantityCell {
    margin-right: 5%;
    margin-left: 5%;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 15%;

    display: flex;
    justify-content: center;
}

.quantityBarcodesSubtable-editingQuantityCell {
    margin-right: 5%;
    margin-left: 5%;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 15%;

    display: flex;
    justify-content: center;
}

.quantityBarcodesSubtable-barcodesCell {
    display: flex;
    flex-direction: row;
    margin-right: 5%;
    margin-left: 5%;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 65%;

    justify-content: center;
}

.quantityBarcodesSubtable-editingBarcodesCell {
    display: flex;
    flex-direction: row;
    margin-right: 5%;
    margin-left: 5%;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 65%;

    justify-content: center;
}

.quantityBarcodesSubtable-barcodeText {
    width: 40%
}

.quantityBarcodesSubtable-printIcon {
    padding: 12px;

}

.barcodesVerticallyCenter {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 100%;
}

.barcodesHorizontallyCenter {
    width: 100%;
    display: flex;
    justify-content: center;
}

.display-none {
    display: none;
}







