.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox {
    padding: 0px 12px 0 3px !important;
}
/*.MuiTableCell-root {
    padding-left: 5px !important;
}*/

.table th {
    font-weight: 700;
    padding: .20rem !important;
    vertical-align: middle !important;

}

.table td {
    vertical-align: middle !important;
}

/* The toolbar below the main toolbar */
.automationRulesSecondaryBar .MuiFormControl-marginDense {
    margin-top: 0px !important;
    margin-bottom: 4px !important;
    margin-left: 8px !important;
 }
.automationRulesSecondaryBar .MuiTablePagination-root {
    margin-bottom: 10px !important;
 }
.automationRulesSecondaryBar .MuiTableCell-root {
    width: 400px;
    margin-left: 15px;
    border-bottom: none !important;
    padding-top: 15px !important;
    padding-bottom: 10px !important;
}
.root {
    height: 100%;
}