.search-field-container {
    display: flex;
    align-items: center;

}

.search-input {
    margin-left: 27px;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 300px !important;
}

.custom-table-pagination {
    text-align: right;
    width: 33%;
}

.custom-filter-field {
    width: 33%;
}