.item-container {
    padding: 20px;
}

.item-container .item-information > div {
    margin-top: 25px;
}

.subtitle {
    margin-bottom: 20px;
}

.item-textfield-container {
    display: flex;
    align-items: center;
    margin-top: 15px;
    height: 70px;
}

.toggle-icon-button svg {
    font-size: 18px;
}

.item-header {
    display: flex;
    justify-content: space-between;
}