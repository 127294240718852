/* The toolbar below the main toolbar */
.secondaryBar .MuiFormControl-marginDense {
    margin-top: 0 !important;
    margin-bottom: 4px !important;
    margin-left: 8px !important;
}
.secondaryBar .MuiTablePagination-root {
    margin-bottom: 10px !important;
}
.secondaryBar .MuiTableCell-root {
    width: 400px;
    margin-left: 15px;
    border-bottom: none !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

/*.MuiTableCell-root {*/
/*    padding: 16px !important;*/
/*}*/

.manage-table-cell {
    padding-left: 16px !important;
}

.cellPadding {
    padding: 5px !important;
}