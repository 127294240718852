.alert-title {
    text-align: left;
    width: 100%;
    display: flex;
    align-items: center;
}

.alert-title-text {
    display: flex;
    justify-content: space-between;
    margin: auto;
    /*margin-top: 2%;*/
    /*margin-left: 3%;*/
    font-weight: bold;
}

.card-header-container {
    position: relative;
}

.grid-layout {
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    grid-gap: 7px;
    padding-left: 60px;
    padding-right: 60px;
}

.grid-header {
    text-align: left;
}

.grid-cell {
    text-align: right;
}

.date-footer {
    justify-content: center;
}

.alert-icon {
    color: #b48609;
}

.snooze-row {
    width: 100%;
    display: flex;
    align-content: center;
    padding: 4px;
}

.snooze {
    align-self: end;
}

.snooze-card {
    width: 25%;
}

.snooze-ok-button {
    align-content: center;
    padding: 5px;
}

.snooze-ok-button:hover {
    color: #005E73;
}

.snooze-ok-button:focus {
    width: 30%;
}

.snooze-cancel-button {
    width: 30%;
}

.snooze-cancel-button:hover {
    color: red;
}

.snooze-icon-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.location-cell {
    font-size: 17px;
}

.snooze-icon {
    /*margin-left: 40px;*/
}

.snooze-icon:hover {
    color: #005E73;
}

.insufficient-icon {
    color: #e39a07
}

.lowInv-icon {
    color: #e39a07
}

.important-alert-icon {
    color: red;
}

.alertCardCellItems {
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.table-container {
    max-height: 200px;
}

.expanded-table-container {
    max-height: 800px;
}

.alerts-card-header {
    display: flex;
    justify-content: space-between;
}

.unshipped-cell {
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    font-size: large;
}

.title-no-icon {
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
}

.alert-main-title {
    font-weight: bold;
    font-size: large;
}

.snooze-align {
    margin: auto;
}