.updateShipmentBtnBox {
    display: flex;
    justify-content: center;
    text-align: center;
}

.updateShipmentBtn {
    margin: 10px;
}

.updateShipmentHeaderBox {
    justify-content: center;
    text-align: center;
}

.tag-checkCircleBox {
    min-width: 80px;
}

.tag-endingBox {
    width: 100%;
}

.tag-input-spec {
    width: 225px;
}

.tag-textBox {
    padding-top: 10px;
    margin-left: 14px;
}
.tag-inputBox {
    width: 300px;
    min-width: 200px;
    min-height: 45px;
}

.createTagButtonBox {
    display: flex;
    justify-content: center;
    text-align: center;
}

.createTagButton {
    margin: 10px;
}
