.filterMatchCategory {
    border: 1px solid #BBBBBB;
    border-radius: 5px;
    margin: 10px;
}

.filterMatchCategoryName {
    min-width: 250px;
    width: 250px;
    text-align: right;
}

.filterMatchCategoryOptions {
    min-width: 234px;
    width: 234px;
    height: 40px;
    margin: 10px;
    text-align: left;
}

.filterMatchCategoryInput {
    min-width: 234px;
    width: 234px;
    text-align: left;
}

.filterMatchCategoryInput .MuiOutlinedInput-input {
    padding: 1.5px 7px !important;
}