.custom-tooltip {
    min-width: 300px; /* Adjust this value to your desired width */
}

.custom-tooltip .MuiTooltip-tooltip {
    font-size: 1rem; /* Adjust the font size if needed */
    padding: 10px;   /* Adjust the padding if needed */
}

.invoice-details {
    display: flex;
    justify-content: space-between;
    width: 100%
}
.invoice-details-label {
    text-align: left;
}
.invoice-details-value {
    margin-left: 10px;
    text-align: right;
}