.filterBooleanPredefinedSelect {
    border: 1px solid #BBBBBB;
    border-radius: 5px;
    margin: 10px;
}

.filterBooleanPredefinedSelectName {
    min-width: 250px;
    width: 250px;
    text-align: right;
}

.filterBooleanPredefinedMarketplaceSelect {
    min-width: 160px;
    width: 160px;
    height: 40px;
    margin: 10px;
    text-align: left;
}

.filterBooleanPredefinedSelectOptions {
    min-width: 234px;
    width: 234px;
    height: 40px;
    margin: 10px;
    text-align: left;
}

.filterBooleanPredefinedSelectOptions2 {
    min-width: 450px;
    width: 450px;
    height: 40px;
    margin: 10px;
    text-align: left;
}

.filterBooleanPredefinedSelectOptionsAmount {
    min-width: 234px;
    width: 234px;
    height: 40px;
    margin: 10px;
    text-align: right;
}

.filterBooleanPredefinedSecondarySelectOptions {
    min-width: 234px;
    width: 234px;
    height: 40px;
    text-align: left;
}

.filterBooleanPredefinedSecondaryMarketplaceSelect {
    min-width: 160px;
    width: 160px;
    height: 40px;
    text-align: left;
    margin-right: 10px;
}

.filterBooleanPredefinedSecondarySelectOptionsShippingTemplate {
    min-width: 290px;
    width: 290px;
    height: 40px;
    text-align: left;
}