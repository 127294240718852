.editIcon {
    width: 82px !important;
}

.claim-cell-center {
    text-align: center !important;
    padding: 3px !important;
}

.claim-cell-left {
    text-align: left !important;
    padding: 3px !important;
}

.claim-cell-right {
    text-align: right !important;
    padding: 3px !important;
}

.claim-cell-center-thin {
    text-align: center !important;
    padding: 3px !important;
    width: 130px !important;
}