.return-form-box {
    display: flex;
    margin-left: 2%;
    flex-wrap: wrap;
    overflow: auto;
}

.return-from-input-box {
    display: flex;
    width: 98%;
    margin-top: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.return-row-flexbox {
    display: flex !important;
    width: auto;
    min-width: 40%;
}
.return-row-flexbox>* {
    flex: 0 0 calc(33.33% - 20px);
}

.text-field {
    margin: 10px !important;
    width: 33%;
}

.column1, .column2, .column3 {
    width: 33.3333%;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: -webkit-center;
}

.first-row-cell {
    margin-top: 20px;
}

.return-dropdowns {
    margin: 10px !important;
    /* width: 335px; */
    width: 33%;
}

.date-fields {
    margin: 10px !important;
    /* width: 200px; */
    width: 33%;
}


.form-buttons {
    width: 270px;
    display: flex;
    justify-content: center;
    margin-bottom: 2%;
}


.return-save-button {
    margin-left: 5px;
    margin-right: 5px;
}

.return-cancel-button {
    margin-left: 5px;
    margin-right: 5px;
}

.return-refund-complete-cancel {
    margin-left: 5px;
    margin-right: 5px;
}

.refund-completed-checkbox {
    margin: 10px !important;
    /* width: 335px; */
    width: 33%;
}


.file-upload {
    margin: 10px !important;
    width: 33%;
}

.file-drop {
    margin: 10px !important;
    width: 335px;
}

@media screen and (max-width: 600px) {
    .return-row-flexbox {
        display: grid !important;
        width: 100%;
        grid-template-columns: 100%;
        min-width: 40%;
        align-items: flex-start;
    }

    .text-field {
        margin: 5px !important;
        width: 100%;
    }

    .file-upload {
        margin: 5px !important;
        width: 100%;
    }

    .file-drop {
        margin: 5px !important;
        width: 100px;
    }

    .return-dropdowns {
        margin: 5px !important;
        /* width: 335px; */
        width: 100%;
    }

    .date-fields {
        margin: 5px !important;
        /* width: 200px; */
        width: 100%;
    }

    .refund-completed-checkbox {
        margin: 5px !important;
        /* width: 335px; */
        width: 100%;
    }

    .empty-box {
        width: 100%;
    }
}

.input-spec-commodity {
    margin: 10px !important;
    width: 46.5%
}

.input-spec-two-col {
    margin: 10px !important;
    max-width: 580px;
}

.row-spec {
    display: flex !important;
    width: auto;
    min-width: 40%;
}

.notesField {
    width: 99%;
    margin-top: 15px;
    margin-left: 10px;
}

.save-spacing {
    margin-left: 20px;
}

.claim-text-field {
    margin: 10px !important;
    /*width: 225px;*/
    width: 275px;
}

.ticket-form-input-box {
    display: flex;
    width: 98%;
    margin-top: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ticket-row-flexbox {
    display: flex !important;
    width: auto;
    min-width: 40%;
    align-items: flex-start;
}

.row-empty-box {
    width: 48px;
    min-width: 48px;
}