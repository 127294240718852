.actionTextField {
    border: 1px solid #BBBBBB;
    border-radius: 5px;
    margin: 10px;
    height: 60px;
}

.actionNumericDropdownField {
    border: 1px solid #BBBBBB;
    border-radius: 5px;
    margin: 10px;
    min-height: 60px;
}

.actionTextFieldName {
    min-width: 250px;
    width: 250px;
    text-align: right;
}

.actionTextFieldInput {
    min-width: 234px;
    width: 234px;
    margin: 10px !important;
    height: 40px;
    text-align: left;
}

.actionTextFieldInput .MuiOutlinedInput-input {
    padding: 10.5px 7px !important;
}

.actionTextEmptyDiv {
    width: 100%;
    text-align: right;
}

.action-text-predefined-value {
    width: 200px !important;
}

.predefined-dropdown {
    min-width: 234px;
    width: 234px;
    height: 40px;
    margin: 10px;
    text-align: left;
}

.package-action-warning {
    align-items: center;
}