
.rowRates {
    display: flex;
    flex-direction: row;
    text-align: center;
    overflow-x: scroll;
    padding-top: 5px;
}

.rowCol {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.rowHeaderCol {
    margin-top: 5px;
}

.rowColTexts {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-left: 20px;
    margin-bottom: 10px;
    min-width: 152px;
}

.rowColOptions {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-bottom: 10px;
}

.ratesEmptyRow {
    height: 23px;
    margin-top: 1px;
    margin-bottom: 16px;
}

.ratingBtns {
    display: flex;
    height: 36px;
}

.ratingRateBtn {
    text-align: left;
    width: 50%;
}

.ratingApplyBtn {
    text-align: right;
    width: 50%;
}

.ratingDivider {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-bottom: 10px;
    min-width: 2px;
}

.ratingCompareBox {
    display: flex;
    margin-right: 10px;
    margin-bottom: 10px;
    min-width: 114px;
    text-align: center;
    align-items: center;
}

.ratingCompareBtn {
    margin: 5px !important;
}