.image {
    max-height: 600px;
    max-width: 500px;
}

.logoImage {
    max-height: 171px;
    max-width: 382px;
}

.file-preview-content {
    display: flex;
}

.file-preview-header {
    font-size: 15px;
    text-align: left;
}

.file-preview-btn-box {
    display: flex;
    align-items: center;
}

.file-preview-btn {
    height: 59px;
}

.logo-preview-header {
    font-size: 15px;
    text-align: center;
}