.page-navigation {
    text-align: center;
}

.dialog-class {
    width: 40%;
    max-width: none;
}

.pdf {
    text-align: center;
}

.react-pdf__Document {
    display: flex;
    justify-content: center;
}