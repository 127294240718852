.box-ledger {
    gap: 32px;
   display: flex;
    margin-left: 34px;
    margin-bottom: 16px;
    margin-top: 30px;
    padding: 0;
}

.divider-ledger {
    width: 100%;
    border-bottom: 1px solid rgb(224, 224, 224);
    display: inline-block;
}

.box-ledger1 {
    gap: 16px;
    display: flex;
    padding: 0;
    margin-top: 24px;
    margin-left: 24px;
}
.rowSpecLedger {
    display: flex !important;
    gap: 10px;
}

.full-width-ledger {
    display: flex;
    width: 200px !important;
    min-width: 200px !important;
    max-width: 200px !important;
}

.full-width-source-statement {
    display: flex;
    width: 250px !important;
    min-width: 250px !important;
    max-width: 250px !important;
}

.full-width-ledger-category {
    display: flex;
    width: 300px !important;
    min-width: 300px !important;
    max-width: 300px !important;
}


.ledger-name {
    display: inline-block; /* Ensures the hover effect applies only to the text */
    width: 100%; /* Ensures the div takes up the full width */
}

.ledger-name:hover {
    cursor: pointer;
    text-decoration: underline; /* Adds underline on hover */
}




.full-width-name {
    display: flex;
    width: 1000px !important;
    min-width: 1000px !important;
    max-width: 1000px !important;
}

.select-year-ledger {
    height: 48px;
    width: 150px; /* Set a fixed width for uniformity */
}

.select-currency-ledger {
    height: 48px;
    width: 200px; /* Set a fixed width for uniformity */
}

.ledger-currency{
    width: 150px;
}

.ledger-circle-icon {
    margin-left: 8px;
    margin-right: 6px;
    margin-top: 18px;
    width: 4%; /* Adjust the size as needed */
    height: 4%; /* Adjust the size as needed */
    border-radius: 50%; /* Makes the div circular */
    background-color: #b0d48c; /* Background color */
    display: flex;
    padding: 10px;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    color: white; /* Text color */
    font-size: 12px; /* Font size */
    font-weight: bold; /* Bold text */
    text-align: center; /* Center text */
}