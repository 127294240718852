.dialogBox {
    max-width: 100% !important;
    display: grid;
    justify-items: center;
}

.filter-field {
    width: 300px !important;
    margin-left: 10px !important;
    margin-bottom: 20px !important;
}

.MuiAutocomplete-tag {
    display: none !important;
}


.filters-title {
    width: 100%;
    display: grid;
    grid-template-columns: 13% 25%;
    justify-content: center;
    align-items: center;
}