.actionNumericField {
    border: 1px solid #BBBBBB;
    border-radius: 5px;
    margin: 10px;
    height: 60px;
}

.actionNumericFieldName {
    min-width: 250px;
    width: 250px;
    text-align: right;
}

.actionNumericFieldInput {
    min-width: 234px;
    width: 234px;
    margin: 10px !important;
    height: 40px;
    text-align: left;
}

.actionNumericFieldInput2 {
    min-width: 107px;
    width: 107px;
    margin: 10px !important;
    height: 40px;
    text-align: left;
}

.actionNumericFieldInput3 {
    min-width: 65px;
    width: 65px;
    margin: 10px !important;
    height: 40px;
    text-align: left;
}

.actionNumericFieldInput3-short {
    min-width: 64px;
    width: 64px;
    margin: 10px !important;
    height: 40px;
    text-align: left;
}

.actionNumericFieldInput .MuiOutlinedInput-input {
    padding: 10.5px 7px !important;
}

.actionNumericFieldInput2 .MuiOutlinedInput-input {
    padding: 10.5px 7px !important;
}

.actionNumericFieldInput3 .MuiOutlinedInput-input {
    padding: 10.5px 7px !important;
}

.actionNumericFieldInput3-short .MuiOutlinedInput-input {
    padding: 10.5px 7px !important;
}

.actionNumericEmptyDiv {
    width: 100%;
    text-align: right;
}

.actionNumericEmptyDiv2 {
    width: 100%;
    text-align: right;
}

.actionNumericEmptyDiv3 {
    width: 100%;
    text-align: right;
}