
.checkbox-input {
    margin: 15px 10px !important;
}

.column {
    display: flex;
    flex-direction: column;
}

.input {
    margin: 10px !important;
}

.createOrderDialogButtonBox {
    display: flex;
    justify-content: center;
    text-align: center;
}

.createOrderDialogButton {
    margin: 10px;
}