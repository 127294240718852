.table-header-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 62% 0% 38%;
    grid-template-rows: auto;
    justify-items: center;
    align-items: center;
    min-height: 85px;
    position: sticky !important;
    top: 139px;
    z-index: 19;
    background-color: white;
}

.table-header-grid-dates {
    width: 100%;
    display: grid;
    grid-template-columns: 45% 5% 50%;
    grid-template-rows: auto;
    justify-items: center;
    align-items: center;
    min-height: 85px;
    position: sticky !important;
    top: 139px;
    z-index: 19;
    background-color: white;
}

.table-header-grid-location {
    width: 100%;
    display: grid;
    grid-template-columns: 80% 1% 19%;
    grid-template-rows: auto;
    justify-items: center;
    align-items: center;
    min-height: 85px;
    position: sticky !important;
    top: 139px;
    z-index: 19;
    background-color: white;
}

.date-filters-container {
    width: 100%;
    display: grid;
    grid-template-columns: 60%;
    justify-content: center;
}

.table-header-search-field {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-left: 70px;
    margin-top: 10px;
}

.table-header-bottom-field {
    display: flex;
    width: 100%;
    margin-left: 70px;
    height: 60px;
}

.table-header-filter-icon {
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;
    width: 15px;
    height: 25px;
}

.table-header-date-field {
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    justify-self: flex-end;
    margin-right: 10px;
}

.sticky-table{
    overflow-x: scroll;
}

.sticky-table-container{
    overflow-x: scroll;
    overflow-y: scroll;
    max-height: 700px;
}

.custom-pagination-field {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    justify-self: flex-end;
    margin-right: 10px;
}

.location-field {
    display: flex;
    justify-content: flex-end;
    justify-self: flex-end;
    margin-left: 17px;
    height: 70px;
    width: 200px !important;
    margin-bottom: 10px;
}

.po-header-search-field {
    width: 280px;
    margin-top: 25px;
    margin-left: 40px;
}

.po-header-location-field {
    display: flex;
    justify-content: end;
    align-items: end;
    width: 280px;
    margin-right: 40px;
    margin-top: 25px;
}

.po-header-search-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 75px;
}

.po-header-filter-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.po-header-filters {
    display: flex;
    justify-content: start;
    align-items: flex-end;
    margin-left: 40px;
    width: 50%;
}

.po-header-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 10px;
    width: 50%;
}

.po-header-date-field {
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    justify-self: flex-end;
    margin-right: 10px;
}

@media (max-width: 800px) {
    .table-header-grid-dates {
        width: 100%;
        display: grid;
        grid-template-columns: 45% 1% 54%;
        grid-template-rows: auto;
        justify-items: center;
        align-items: center;
        min-height: 85px;
        position: sticky !important;
        top: 139px;
        z-index: 19;
        background-color: white;
    }
    .table-header-grid-location {
        width: 100%;
        display: grid;
        grid-template-columns: 80% 1% 19%;
        grid-template-rows: auto;
        justify-items: center;
        align-items: center;
        min-height: 85px;
        position: sticky !important;
        top: 139px;
        z-index: 19;
        background-color: white;
    }
    .table-header-date-field {
        margin-top: 5px; 
        width: 97%;
        display: flex;
        justify-content: flex-start;
        justify-self: flex-start;
        margin-right: 10px;
        margin-left: 5px;
        margin-top: 5px;
    }
    .custom-pagination-field {
        width: 400px;
        display: flex;
        justify-content: flex-start;
        justify-self: flex-start;
        /* margin-right: 10px; */
    }
    .location-field {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        justify-self: flex-end;
        margin-right: 10px;
        margin-bottom: 10px;
        /* margin-left: 25px; */
    }
    .table-header-search-field {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-left: 50px;
        margin-top: 10px;
    }
    
    .po-header-filter-row {
        display: grid;
        width: 100%;
    }
    
    .po-header-filters {
        display: flex;
        justify-content: start;
        align-items: flex-end;
        margin-left: 40px;
        width: 100%;
    }
    
    .po-header-pagination {
        display: flex;
        justify-content: start;
        align-items: flex-end;
        margin-left: 17px;
        width: 100%;
    }
}

@media (max-width: 650px) {
    /* CSS that should be displayed if width is equal to or less than 650px goes here */

    .table-header-grid {
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        justify-items: center;
        align-items: center;
        min-height: 85px;
        position: sticky !important;
        top: 139px;
        z-index: 19;
        background-color: white;
    }

    .table-header-bottom-field {
        display: flex;
        width: 100%;
        margin-left: 20px;
    }

    .table-header-grid-dates {
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        justify-items: center;
        align-items: center;
        min-height: 85px;
        position: sticky !important;
        top: 139px;
        z-index: 19;
        background-color: white;
    }

    .table-header-grid-location {
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        justify-items: center;
        align-items: center;
        min-height: 85px;
        position: sticky !important;
        top: 139px;
        z-index: 19;
        background-color: white;
    }

    .table-header-search-field {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-left: 20px;
        margin-top: 10px;
        margin-right: 10px;
    }

    .search-field {
        width: 80%;
    }

    .custom-pagination-field {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        justify-self: flex-start;
        /* margin-right: 10px; */
    }

    .location-field {
        width: 98%;
        display: flex;
        justify-content: flex-start;
        justify-self: flex-start;
        margin-right: 10px;
        margin-left: 5px !important;
    }

    .table-header-date-field {
        margin-top: 5px; 
        width: 97%;
        display: flex;
        justify-content: flex-start;
        justify-self: flex-start;
        margin-right: 10px;
        margin-left: 5px;
        margin-top: 5px;
    } 

    .po-header-search-field {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin: 5px;
        margin-bottom: 0px;
        margin-top: 10px;
    }

    .po-search-field {
        width: 100%;
    }
    
    .po-header-search-row {
        display: grid;
        height: auto;
        width: 100%;
        grid-template-columns: 100%;
    }

    .po-header-date-field {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        justify-self: flex-start;
        margin-right: 5px;
    }

    .po-header-filter-row {
        display: grid;
        width: 100%;
    }
    
    .po-header-filters {
        display: flex;
        justify-content: start;
        align-items: flex-end;
        margin: 10px;
        margin-left: 10px;
        width: 100%;
        margin-bottom: 0px;
    }
    
    .po-header-pagination {
        display: flex;
        justify-content: start;
        align-items: flex-end;
        margin: 5px;
        margin-left: 0px;
        width: 100%;
    }
  }