.page-navigation {
    text-align: center;
    padding-top: 10px;
}

.dialog-class {
    width: 100%;
    max-width: none;
}

.pdf {
    text-align: center;
}

.react-pdf__Document {
    justify-content: center;
    display: flex;
}

.react-pdf__message--no-data{
    padding-top: 10px;
    text-align: center;
    height: 1188px;
    width: 918px;
}

.react-pdf__message{
    padding-top: 10px;
    text-align: center;
    height: 1188px;
    width: 918px;
}


.selecting {
    cursor: crosshair;
}

.hider {
    display: none;
}