.title {
    background-color: #fff;
    padding-right: 1.6%;
    padding-left: 1.3%;
    padding-top: 1.3%;
}

.missing-shipments-table {
    background-color: #fff;
    margin: 20px;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.23);
    /*width: 100%;*/
    /*overflow: auto;*/
}

.missing-shipments-container {
    background-color: #fff;
    margin: 20px;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.23);
    /*width: 100%;*/
    /*overflow: auto;*/
}