.invoice-template-tab {
    min-width: 200px !important;
    max-width: 200px !important;
    font-weight: bold !important;
    color: black;
    border: 2px solid #acd685 !important;
    border-radius: 4px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: none !important;
    background-color: #92C95F !important;
    outline: none !important;
}

.invoice-template-tab-not-selected {
    min-width: 200px !important;
    max-width: 200px !important;
    font-weight: bold !important;
    color: black;
    border: 2px solid #eaeaea !important;
    border-radius: 5px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: none !important;
    outline: none !important;
}