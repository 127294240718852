/* Base styles */

.box {
  margin-left: 308px;
  margin-bottom: 0;
  margin-top: 40px;
  display: inline-flex;
  padding: 0;
  gap: 16px;
  align-items: center;
}

.box1 {
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 6px;
  padding: 0;
  gap: 16px;
  align-items: center;
  display: flex !important;
}


.box1-purchase-summary {
  margin-left: 0;
  margin-bottom: 0;
  margin-top: 6px;
  padding: 0;
  gap: 16px;
  align-items: center;
  display: flex !important;
}

.table-page-summary {
  margin-top: -170px;
}

.table-page-purchase-summary {
  margin-top: -152.5px;
}

.checkbox-purchase-container {
  margin-left: 30px;
  margin-top: 16px;
}

.box-statement {
  margin-left: 24px;
  margin-top: 40px;
  margin-bottom: 80px;
  display: inline-flex;
  padding: 0;
  gap: 16px;
  align-items: center;
}

.checkbox-shipping-container {
  margin-left: 30px;
  margin-top: 10px;
}


.custom-cell {
  padding: 20px 0 !important;
}

.align-tax {
  width: 20%;
}

.center {
  text-align: center !important;
}

.table-tax-cell-left {
  text-align: left !important;
  padding-left: 24px !important;
}

.total-field {
  text-align: right;
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 16px;
}

.shipping {
  margin-right: 2.8%;
}

.purchase {
  margin-right: 4.2%;
}

.taxSummary {
  margin-right: 1.8%;
}

.dollar-sign {
  padding-right: 50px; /* Adjust this value as needed */
}

.dollar-amount {
  text-align: center;
}

.dollar-sign-total {
  padding-right: 50px; /* Adjust this value as needed */
}

.dollar-amount-total {
  text-align: right;
}

.select-carrier {
  height: 48px;
  width: 210px; /* Set a fixed width for uniformity */
}

.select {
  height: 48px;
  width: 150px; /* Set a fixed width for uniformity */
}

.select-year {
  height: 48px;
  width: 150px; /* Set a fixed width for uniformity */
}

.select-month {
  height: 48px;
  width: 150px; /* Set a fixed width for uniformity */
}

.select-currency {
  height: 48px;
  width: 180px; /* Set a fixed width for uniformity */
}

.chip {
  margin-right: 12px; /* 1.5 * 8px for marginRight 1.5 */
  font-size: 14px;
}

.chip-selected {
  background-color: #005e73 !important;
  color: white !important; /* Adjust color as needed */
}

.chip-container {
  display: flex;
  gap: 10px; /* Use gap for spacing between chips */
  flex-wrap: nowrap; /* Prevent wrapping on larger screens */
}
/* Responsive styles */
@media (max-width: 1500px) {
  .table-page-summary {
    margin: 0;
  }

  .table-page-purchase-summary {
    margin: 0;
  }

  .checkbox-shipping-container {
    margin: 0 !important;
  }

  .box-statement {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 24px; /* Adjust as needed */
    margin-bottom: 80px;
    margin-top: 20px;
    row-gap: 32px;
  }

  .box {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 31px; /* Adjust as needed */
    margin-bottom: -28px;
    margin-top: 20px;
    row-gap: 32px;
  }

  .box1 {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px; /* Adjust as needed */
    margin-bottom: -24px;
    margin-top: 44px;
    row-gap: 0;
  }

  .form-control {
    width: 92.5%;
  }

  .select {
    width: 92.5%;
  }

  .select-year {
    width: 92.5%;
  }

  .select-month {
    width: 92.5%;
  }

  .chip {
    margin-right: 8px;
    margin-bottom: 8px; /* Space between chips in column layout */
  }

  .chip-container {
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .table-page-summary {
    margin-top: 0;
  }

  .dollar-sign {
    padding-left: 8px;
    padding-right: 24px; /* Adjust this value as needed */
  }
  .box {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px; /* Adjust as needed */
    margin-bottom: -28px;
    margin-top: 20px;
    row-gap: 32px;
  }

  .box-statement {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 24px; /* Adjust as needed */
    margin-bottom: 0;
    margin-top: 0;
    row-gap: 32px;
  }
  .checkbox-shipping-container {
    margin-left: 0;
  }

  .form-control {
    width: 92.5%;
  }

  .select {
    width: 92.5%;
  }

  .select-year {
    width: 92.5%;
  }

  .select-month {
    width: 92.5%;
  }

  .chip {
    margin-right: 8px;
    margin-bottom: 8px; /* Space between chips in column layout */
  }

  .chip-container {
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
  }
}

/*settings*/

.summary-stepper-align {
  margin-top: 32px;
  align-items: center;
}

.summary-add-button {
  width: 300px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.summary-stepper-page-two-grid {
  display: grid;
  justify-content: center;
  grid-template-columns: 225px 250px 250px 250px;
  grid-template-rows: auto auto auto auto;
  grid-row-gap: 12px;
  justify-items: center;
  align-items: center;
}

.summary-input-text-field-large {
  margin: 10px !important;
  width: 470px;
}

.summary-input-text-field {
  margin: 10px !important;
  width: 225px;
}

.summary-delete-row-step-one {
  display: flex;
  justify-self: flex-start;
}

@media screen and (max-width: 600px) {
  .summary-stepper-page-two-grid {
    grid-template-columns: 5% 35% 30% 30%;
  }
  .summary-input-text-field {
    margin: 5px !important;
    width: 100% !important;
  }
}
