.barcode-section {
    display: flex;
    align-items: center;
    justify-content: start;
}

.qty-field {
    width: 100%;
}

.barcode-field {
    width: 30%;
}

.barcode-listings {
    width: 100%;
    display: flex;
    align-content: flex-end;
    align-items: center;
    /* padding: 9px; */
    padding-left: 10px;
}

.barcodes {
    display: flex;
    justify-content: right;
    align-items: flex-end;
    flex-direction: column;
}

.qty-column {
    padding-top: 23px !important;
    width: 7%;
    vertical-align:top !important;
}

.single-qty-column {
    width: 7%;
}

.shortcode-column {
    width: 9%;
}

.productCode-column {
    width: 10%;
}

.delete-row {
    display: flex;
}

.some-page-wrapper {

}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    align-content: center;
    align-items: center;
    height: 10%;
}

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.nested-column {
    height: 100px;
}

.display-none {
    display: none !important;
}

.barcode-value {
    padding-right: 15px;
    width: 115px;
}

.barcode-column {
    width: 43%;
    padding-left: 50px;
    margin-left: 50px;
}

.sku-column {
    width: 13%;
}
.primary-column {
    width: 5%;
}
.loadingBox {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.getLoadingOuterBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}