.header {
    display: flex;
    justify-content: center;
}

.threshold-container {
    display: flex;
    margin: 20px;
}

.threshold-form {
    margin-left: 27.5%;
    flex: 1;
}

.threshold-table {
    margin-right: 22.5%;
    flex: 1;
}
.empty-table{
    margin-top: 15px;
}

.table-cell {
    min-width: 175px;
    text-align: center !important;
    font-weight: bold !important;
    border: 1px solid rgba(224, 224, 224, 1);
}

.location-name {
    margin: 20px;
}

.shortcode-autocomplete {
    width: 225px !important;
    margin: 20px;
}

.threshold-field {
    margin: 20px;
}

.buttons{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.form-buttons{
    flex: 1;
}