
.rateCell #text {
    padding: 5px !important;
    margin-top: 2px;
}

.cellPadding {
    padding: 5px !important;
    width: 100px;
}

.namecellPadding {
    padding: 5px !important;
    width: 150px;
}

