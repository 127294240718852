.conditionFiltersDialogContent {
    max-width: 520px;
    min-width: 520px;

    /* do not change. 
     * this works in conjunction with max-height defined in ConditionFiltersTable.conditionFiltersContainer.
     * this ensures that the search bar does not jump all over the place as the user searches for the filters */
    min-height: 572px;
}

.conditionActionsDialogContent {
    max-width: 520px;
    min-width: 520px;

    /* do not change.
     * this works in conjunction with max-height defined in ConditionFiltersTable.conditionFiltersContainer.
     * this ensures that the search bar does not jump all over the place as the user searches for the filters */
    min-height: 572px;
}