.status {
    minWidth: 215px;
    width: 215px;
    paddingLeft: 4px;
    paddingRight: 3px;
    paddingTop: 0px;
    paddingBottom: 0px;
}

.shipmentStatus {
    height: 20px;
    width: 150px;
}

.col-cell-center {
    text-align: center !important;
    padding: 3px !important;
    width: 100px !important;
}

.col-cell-center-primeImage {
    text-align: center !important;
    padding: 3px !important;
    min-width: 70px !important;
}

.col-cell-center-orderId {
    text-align: center !important;
    padding: 3px !important;
    min-width: 200px !important;
}

.col-cell-center-name {
    text-align: center !important;
    padding: 3px !important;
    min-width: 130px !important;
}

.col-cell-center-orderDate {
    text-align: center !important;
    padding: 3px !important;
    min-width: 135px !important;
}

.col-cell-center-shipMethod {
    text-align: center !important;
    padding: 3px !important;
    min-width: 145px !important;
}