
.rowSpec {
    display: flex !important;
    width: 40vw;
}

.rowCarrier {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.formInput {
    margin: 10px 10px 10px 0px !important;
    width: 95%;
}



.formInputsingle {
    margin: 10px !important;
    width: 40vw;
}

.formTwoColInput {
    margin: 10px !important;
    width: 33%;
}

.formThreeInput {
    margin: 10px !important;
    width: 33%
}

.formTwoInput {
    margin: 10px !important;
    width: 66%
}


@media screen and (max-width: 600px) {
    .rowSpec {
        display: grid !important;
        width: 100%;
        grid-template-columns: 100%;
        min-width: 40%;
    }
    .formTwoInput {
        margin: 5px !important;
        width: 100%;
    }
    .formThreeInput {
        margin: 5px !important;
        width: 100%;
    }
    .formInputsingle {
        margin: 5px !important;
        width: 100%;
    }
    .formTwoColInput, .formInput {
        margin: 5px !important;
        width: 100%;
    }
}