

.full-width-bar {
    display: flex;
    width: 100%;
}

.flex-row-align-left {
    display: flex;
    justify-content: flex-start;
}

.flex-row-align-right {
    display: flex;
    justify-content: flex-end;
}

.half-row-align-left {
    display: flex;
    justify-content: flex-start;
    width: 50%;
}

.full-row-align-center {
    display: flex;
    justify-content: center;
    width: 100%;
}

.half-row-align-right {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

.spacer {
    width: auto;
}

.filter-tooltip {
    padding-top: 10px;
}

.standard-margin-1 {
    margin: 20px;
}

.standard-margin-top {
    margin-top: 20px;
}

.standard-margin-left {
    margin-left: 20px;
}

.standard-margin-bottom {
    margin-bottom: 20px;
}

.standard-margin-right {
    margin-right: 20px;
}

.margin-vertical-1 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.standard-checkbox-margin-1 {
    margin: 5px;
    margin-top: 0px;
    display: flex;
}

.standard-filter-tag-row-margin {
    margin: 5px;
    margin-top: 10px;
}

.tablepage-spacer {
    width: auto;
    flex-grow: 1;
}

.spacer-10px {
    width: 10px;
}

.spacer-15px {
    width: 15px;
}

.vertically-center {
    margin-top: auto;
    margin-bottom: auto;
}

.horizontally-center {
    margin-left: auto;
    margin-right: auto;
}

.horizontally-center-row {
    justify-content: center;
    display: flex;
    width: 100%;
}















