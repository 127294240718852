.info-drawer {
    float: right;
    display: flex;
    flex-wrap: wrap;
    margin-right: 1%;
    margin-top: 2%;
}

.no-inventory-card {
    text-align: center;
}

.inventory-table {
    height: 100%;
    top: 0;
    position: relative;
    transition: all .9s ease-out;

}

.fade-inventory-table {
    height: 100%;
    top: 0;
    position: relative;
    transition: all .9s ease-out;
}