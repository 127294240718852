.alerts-component-logout {

}

.top-nav {
    width: 100%;
    background-color: #005E73;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
}

.menu-toggle-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.35%;
    margin-right: 1%;
}

.menu-drawer {
    width: 200px;
}

.menu-drawer-header-text {
    margin-top: 15px;
    font-size: 20px;
    margin-left: 15px;
}

