.filterTextField {
    border: 1px solid #BBBBBB;
    border-radius: 5px;
    margin: 10px;
}

.filterTextFieldName {
    min-width: 250px;
    width: 250px;
    text-align: right;
}

.filterTextFieldOptions {
    min-width: 234px;
    width: 234px;
    height: 40px;
    margin: 10px;
    text-align: left;
}

.filterTextFieldInput {
    min-width: 234px;
    width: 234px;
    margin: 10px;
    text-align: left;
}
.filterDateInput {
    width: calc(50% - 25px);
    margin: 10px !important;
    display: inline-block;
}

.filterTextFieldInput .MuiOutlinedInput-input {
    padding: 10.5px 7px !important;
}

.infoTooltipIcon {
    padding-left: 10px;
    float: left;
}