
.user-info-body {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.displayname-edit {
    margin-bottom: 20px !important;
    width: 300px
}

.authority-edit {
    width: 300px;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.password-btn {
    width: 200px;
    margin-bottom: 20px !important;
}

.authority-box {
    width: 410px;
    display: flex;
    align-items: center;
}

.authority-space {
    width: 30px;
}

.default-section {
    width: 410px;
    display: flex;
    align-items: center;
}

.authority-text-disabled {
    color: rgba(0, 0, 0, 0.26);
}

@media screen and (max-width: 600px) {
    .default-section {
        width: 30%;
    }
    .authority-box {
        width: 70%;
        display: flex;
        align-items: center;
    }
}