.updateShipmentBtnBox {
    display: flex;
    justify-content: center;
    text-align: center;
}

.updateShipmentBtn {
    margin: 10px;
}

.updateShipmentHeaderBox {
    justify-content: center;
    text-align: center;
}

.checkCircleBox {
    min-width: 80px;
}

.endingBox {
    width: 100%;
}

.editingEndingBox {
    min-width: 500px !important;
}

.input-spec {
    width: 225px;
}

.textBox {
    padding-top: 10px;
    margin-right: 30px;
}
.inputBoxAmz {
    min-width: 12vw;
    min-height: 45px;
    justify-content: center !important;
}

.createButtonBox {
    display: flex;
    justify-content: center;
    text-align: center;
}

.createButton {
    margin: 10px;
}
