.ticket-column1, .ticket-column2, .ticket-column3 {
    width: 33.3333%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: -7px;
    text-align: -webkit-center;
}

.section-divider {
    width: 98%;
    margin-top: 1% !important;
    margin-bottom: 1% !important;
}

.sub-section-divider {
    width: 93%;
    margin-top: 1% !important;
    margin-bottom: 1% !important;
}

.ticket-text-field {
    margin: 10px !important;
    /*width: 225px;*/
    width: 275px;
}

.file-upload {
    margin: 10px !important;
    width: 225px;
}

.ticket-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: auto;
}

.ticket-form-input-box {
    display: flex;
    width: 98%;
    margin-top: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ticket-row-flexbox {
    display: flex !important;
    width: auto;
    min-width: 40%;
    align-items: flex-start;
}

.ticket-comment-row-flexbox {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.row-empty-box {
    width: 48px;
    min-width: 48px;
}

.remove-icon {
    margin-top: 12px;
}

.add-action-button {
    width: 143px !important;
    text-align: center;
}

.ticket-action-container {
    height: 100%;
    margin-bottom: 2%;
    justify-content: center;
}

.file-drop {
    margin: 10px !important;
    width: 335px;
}

.action-divider {
    margin-bottom: 20px !important;
}

.delete-action-container {
    min-width: 24.5%;
}

.top-bottom-row-cells {
    margin-top: 20px;
}

.action-autocomplete-flex {
    display: flex;
    align-items: center;
}

.empty-box {
    width: 225px;
}


.tasks-checkbox-list {
    margin-top: 10px;
}

.buttons {
    width: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;

}

.ticket-save-button {
    margin: 5px;
}

.ticket-cancel-button {
    margin: 5px;
}

@media screen and (max-width: 600px) {
    .ticket-row-flexbox {
        display: grid !important;
        width: 100%;
        grid-template-columns: 100%;
        min-width: 40%;
        align-items: flex-start;
    }

    .ticket-text-field {
        margin: 5px !important;
        width: 100%;
    }
    
    .file-upload {
        margin: 5px !important;
        width: 100%;
    }

    .empty-box {
        width: 100%;
    }
}