.leftMenu {
  min-width: 240px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 75px;
  overflow-y: scroll;
  height: 100vh !important;
  padding-bottom: 100px;

}

.leftMenu-item {
  font-size: 14px;
  line-height: 50px;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  color: black;
}

.single-menu-item {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}


.leftMenu-item-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
  color: #000000;

}

.leftMenu-item-icon {
  margin-right: 6px;
  text-decoration: none;
}

.leftMenu-item-text {
  width: 100%;
  text-decoration: none;
}

.leftMenu-item-expand-arrow {
  font-size: 1.2rem !important;
}

.leftMenu-item-expand-arrow-expanded {
  color: #000000;
  font-weight: bold;
}

.leftMenu-menuitem-text {
  font-size: 0.8rem;
}

.left-menu-navigation {
  width: 100%;
  text-decoration: black;
  color: black;

  /* transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
}

.left-menu-navigation:hover {
  background-color: rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 800px) {
  .leftMenu {
    width: 100%;
  }
}